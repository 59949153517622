//The medium of the media. i.e. "video" or "audio"
import { AdConfig } from './AdConfig';
import { TextTrack } from './TextTrack';

export enum Medium {
  VIDEO,
  AUDIO
}

// media object interface
export interface Media {
  cust_params: any;
  title?: string;
  src: string;
  type?: string;
  medium?: Medium;
  poster?: string;
  startTime?: number;

  contentSourceId?: string;
  // Identifier for the video content source
  videoId?: string | null;
  //The stream request API key
  apiKey?: string;
  //This is used to determine which stream should be played
  assetKey?: string;
  keySystems?: any;
  textTrack?: TextTrack[] | undefined;
  advertising?: AdConfig;
  userAgent?: string;
}
