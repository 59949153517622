import { playerEventsConstants } from '../constants/playerConstants';

export function showTobaccoAdvisory(
  videoPlayer: { el: () => any },
  tobaccoImgPath: string,
  audioLanguage: string,
  analyticsManager: any
) {
  let tobaccoAdvisoryContainer = document.querySelector('.tobaccoAdvisory');
  let eventPayLoad = {
    healthSpotPlacement: 'Aston Band'
  };
  if (
    tobaccoAdvisoryContainer &&
    tobaccoAdvisoryContainer instanceof HTMLElement
  ) {
    let tobaccoAdvisoryContainerLanguage =
      tobaccoAdvisoryContainer.getAttribute('data-language');
    if (tobaccoAdvisoryContainerLanguage !== audioLanguage) {
      tobaccoAdvisoryContainer.setAttribute('data-language', audioLanguage);
      let tobaccoAdvisoryIcon = document.querySelector('.tobaccoAdvisoryIcon');
      if (tobaccoAdvisoryIcon instanceof HTMLImageElement) {
        tobaccoAdvisoryIcon.src = tobaccoImgPath;
      }
    }
    if (tobaccoAdvisoryContainer.style.display !== 'block') {
      analyticsManager.reportEvent(
        playerEventsConstants.healthSpotViewed,
        eventPayLoad
      );
      tobaccoAdvisoryContainer.style.display = 'block';
    }
  } else {
    const tobaccoAdvisory = document.createElement('div');
    tobaccoAdvisory.setAttribute('class', 'tobaccoAdvisory');
    tobaccoAdvisory.setAttribute('data-language', audioLanguage);
    tobaccoAdvisory.style.display = 'block';
    const advisoryImg = document.createElement('img');
    advisoryImg.setAttribute('class', 'tobaccoAdvisoryIcon');
    advisoryImg.src = tobaccoImgPath;
    tobaccoAdvisory.appendChild(advisoryImg);
    const videoEL = videoPlayer && videoPlayer.el();
    videoEL && videoEL.appendChild(tobaccoAdvisory);
    analyticsManager.reportEvent(
      playerEventsConstants.healthSpotViewed,
      eventPayLoad
    );
  }
}

export function removeTobaccoAdvisory() {
  let tobaccoAdvisoryContainer = document.querySelector('.tobaccoAdvisory');
  if (
    tobaccoAdvisoryContainer &&
    tobaccoAdvisoryContainer instanceof HTMLElement
  ) {
    tobaccoAdvisoryContainer.style.display = 'none';
  }
}
