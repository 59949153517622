/* eslint-disable */
// export class Utils{

import { ZeePlayerConfig } from '../configs/ZeePlayerConfig';
import {
  playerEventsConstants,
  videoQualityMixpanelOptions
} from '../constants/playerConstants';

//     static base64EncodeUint8Array(input:any) {
//         var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
//         var output = "";
//         var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
//         var i = 0;

//         while (i < input.length) {
//             chr1 = input[i++];
//             chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
//             chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

//             enc1 = chr1 >> 2;
//             enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
//             enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
//             enc4 = chr3 & 63;

//             if (isNaN(chr2)) {
//                 enc3 = enc4 = 64;
//             } else if (isNaN(chr3)) {
//                 enc4 = 64;
//             }
//             output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
//                 keyStr.charAt(enc3) + keyStr.charAt(enc4);
//         }
//         return output;
//     }
// }

export const convertToSeconds = (value: any) => {
  let convertedTime;
  if (value)
    convertedTime = value
      .split(':')
      .reduce((acc: number, time: string | number) => 60 * acc + +time);
  return convertedTime;
};

export const isNotTvodContent = (playerRef: {}) => {
  const { singlePlayDetails = {} }: any = playerRef || {};
  const { entitlement: { isTVOD = 0 } = {} } = singlePlayDetails || {};
  return !isTVOD;
};

export const getBrowserType = () => {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf('Chrome') > -1;

  // Detect Internet Explorer
  let IExplorerAgent =
    userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1;

  // Detect Firefox
  let firefoxAgent =
    userAgentString.indexOf('Firefox') > -1 ||
    userAgentString.includes('Firefox');

  // Detect Safari
  let safariAgent = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  let operaAgent = userAgentString.indexOf('OP') > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;
  if (chromeAgent) {
    return 'Chrome';
  } else if (firefoxAgent) {
    return 'FireFox';
  } else if (IExplorerAgent) {
    return 'Ie';
  } else if (safariAgent) {
    return 'Safari';
  } else if (operaAgent) {
    return 'Opera';
  }
};

export const createElement = ({
  element,
  className,
  text = '',
  attributes = [],
  targetDiv
}: {
  element: string;
  className: string;
  text: string;
  attributes: Array<{ attribute: string; value: string }>;
  targetDiv: HTMLElement;
}) => {
  if (document.querySelector(`.${className}`)) return null; // TO be checked
  const target = document.createElement(element);
  if (text) {
    target.innerHTML = text;
  }
  attributes.forEach(
    ({ attribute, value }: { attribute: string; value: string }) =>
      target.setAttribute(attribute, value)
  );
  if (targetDiv) {
    targetDiv.appendChild(target);
  }
};

export const base64DecodeUint8Array = (a: string) => {
  var b = window.atob(a),
    c = b.length,
    d = new Uint8Array(new ArrayBuffer(c));
  for (let i = 0; i < c; i++) d[i] = b.charCodeAt(i);
  return d;
};

export const base64EncodeUint8Array = (a: string | any[]) => {
  for (
    var d: number,
      e: number,
      f: number,
      g: number,
      h: number,
      i: number,
      j: number,
      b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
      c = '',
      k = 0;
    k < a.length;

  )
    (d = a[k++]),
      (e = k < a.length ? a[k++] : Number.NaN),
      (f = k < a.length ? a[k++] : Number.NaN),
      (g = d >> 2),
      (h = ((3 & d) << 4) | (e >> 4)),
      (i = ((15 & e) << 2) | (f >> 6)),
      (j = 63 & f),
      isNaN(e) ? (i = j = 64) : isNaN(f) && (j = 64),
      (c += b.charAt(g) + b.charAt(h) + b.charAt(i) + b.charAt(j));
  return c;
};

export const getTimeStamp = () => {
  let timeStamp;
  const randomnumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
  timeStamp = randomnumber + Date.now();
  return timeStamp;
};

export const arrayToString = (a: { buffer: Iterable<number> }) => {
  let b: any = new Uint16Array(a.buffer);
  return String.fromCharCode.apply(null, b);
};

// Helper methods
export const setLocalStorage = (key: string, value: string) => {
  if (typeof value === 'string') {
    if (value) {
      localStorage.setItem(key, value);
    }
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
export const getLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    let retrievedData = localStorage && localStorage.getItem(key);
    if (retrievedData && typeof retrievedData !== 'string') {
      return JSON.parse(retrievedData);
    } else {
      return retrievedData || '';
    }
  }
};
export const getUserId = (userType: string) => {
  if (userType === 'guest') {
    return getLocalStorage('guestToken');
  } else {
    return getLocalStorage('token');
  }
};
export const deleteLocalStorage = (key: string) => {
  if (localStorage && localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
};
export const getLocalStorageInteger = (key: string) => {
  if (typeof window !== 'undefined') {
    let retrievedData: string | null = null;
    if (localStorage && localStorage.getItem(key)) {
      retrievedData = localStorage.getItem(key);
      if (typeof retrievedData === 'string') {
        return parseInt(retrievedData, 10);
      }
      return retrievedData;
    }
    return retrievedData;
  }
};

export function isTataSkyUser() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const partnerName = urlParams.get('partner');
  return partnerName === 'tataskybinge';
}

export function isIos() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

// To check platform name
export const getPlatformName = () => {
  let platformName = '';
  const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
  if (
    !isServer &&
    (navigator.userAgent?.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|Windows Phone/i
    ) ||
      window.innerWidth <= 480) &&
    !window.navigator.userAgent.toLowerCase().match(/tv/i)
  ) {
    platformName = 'mobile_web';
  } else {
    platformName = 'desktop_web';
  }
  return platformName;
};

export const toggleKeyMoments = (isClickable = true) => {
  let keyMoments = document.getElementById('keyMoments');
  let keyMomentsWrapper: HTMLElement | null =
    document.querySelector('.keyMomentsWrapper');
  if (isClickable) {
    keyMoments ? (keyMoments.style.pointerEvents = 'auto') : '';
    keyMomentsWrapper ? (keyMomentsWrapper.style.pointerEvents = 'auto') : '';
  } else {
    keyMoments ? (keyMoments.style.pointerEvents = 'none') : '';
    keyMomentsWrapper ? (keyMomentsWrapper.style.pointerEvents = 'none') : '';
  }
};

export const toggleWaterMark = (showWaterMark = true) => {
  let waterMark: HTMLElement | null = document.querySelector(
    '.watermarkContainer'
  );
  if (showWaterMark && waterMark) {
    waterMark.style.display = 'block';
  } else if (waterMark) {
    waterMark.style.display = 'none';
  }
};

export const toggleFreeMinutes = (showFreeMinutes = true) => {
  let freeMinutesInfo: HTMLElement | null = document.querySelector(
    '.freeMinutesInfoContainer'
  );
  if (showFreeMinutes && freeMinutesInfo) {
    freeMinutesInfo.style.display = 'block';
  } else if (freeMinutesInfo) {
    freeMinutesInfo.style.display = 'none';
  }
};

export const getCookieData = (cname: string) => {
  if (typeof window !== 'undefined') {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
};

export const getLanguageName = (langCode: string) => {
  const translationsAndConfigs = (window as any).translationsAndConfigs;
  langCode = langCode.toLowerCase();
  switch (langCode) {
    case 'en':
    case 'english':
      return translationsAndConfigs.messages.english;
    case 'hi':
    case 'hindi':
      return translationsAndConfigs.messages.hindi;
    case 'mr':
    case 'marathi':
      return translationsAndConfigs.messages.marathi;
    case 'te':
    case 'telugu':
      return translationsAndConfigs.messages.telugu;
    case 'kn':
    case 'kannada':
      return translationsAndConfigs.messages.kannada;
    case 'ta':
    case 'tamil':
      return translationsAndConfigs.messages.tamil;
    case 'ml':
    case 'malayalam':
      return translationsAndConfigs.messages.malayalam;
    case 'bn':
    case 'bengali':
      return translationsAndConfigs.messages.bengali;
    case 'gu':
    case 'gujarati':
      return translationsAndConfigs.messages.gujarati;
    case 'ur':
      return 'Urdu';
    case 'pa':
    case 'punjabi':
      return translationsAndConfigs.messages.punjabi;
    case 'hr':
    case 'bhojpuri':
      return translationsAndConfigs.messages.bhojpuri;
    case 'or':
      return 'Oriya';
    case 'de':
      return 'German';
    case 'th':
      return 'Thai';
    case 'id':
      return 'Indonesian';
    case 'in':
      return 'Indonesian';
    case 'ms':
      return 'Malay';
    case 'ru':
      return 'Russian';
    case 'zho':
      return 'Chinese';
    case 'es':
      return 'Spanish';
    case 'ja':
      return 'Japanese';
    case 'fr':
      return 'French';
    case 'Off':
      return translationsAndConfigs.messages.off;
    default:
      return langCode;
  }
};

export const convertSecsToTime = (secs: number) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs - hours * 3600) / 60);
  const seconds = (secs - hours * 3600 - minutes * 60).toFixed(0);
  var timeString =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0');
  return timeString;
};

export const getBitrateSelectedText = (qualityLevels: any) => {
  let selectedIndex = qualityLevels.selectedIndex;
  let selectedLevel = qualityLevels[selectedIndex];
  if (
    selectedLevel &&
    (selectedLevel.height == '240' ||
      selectedLevel.height == '480' ||
      selectedLevel.height == '720' ||
      selectedLevel.height == '1080')
  ) {
    let label = '';
    if (selectedLevel.height) {
      if (selectedIndex === 0) {
        label = 'Auto';
        if (selectedLevel.height == '1080') {
          label = 'Full HD';
        }
      } else if (selectedLevel.height == '240') {
        label = 'Data Saver';
      } else if (selectedLevel.height == '480') {
        label = 'SD';
      } else if (selectedLevel.height == '720') {
        label = 'HD';
      } else if (selectedLevel.height == '1080') {
        label = 'Full HD';
      }
      return label;
    }
  } else {
    return false;
  }
};

export const isMobileDevice = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (
    userAgent.match(/android/i) ||
    userAgent.match(/iphone/i) ||
    userAgent.match(/ipad/i) ||
    userAgent.match(/ipod/i) ||
    userAgent.match(/windows phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getAffiliate = () => {
  let partnerSource: any;
  try {
    if (
      sessionStorage &&
      sessionStorage.getItem(playerEventsConstants.PARTNER_NAME)
    ) {
      partnerSource = sessionStorage.getItem(
        playerEventsConstants.PARTNER_NAME
      );
      partnerSource = JSON.parse(partnerSource);
    } else if (
      localStorage &&
      localStorage.getItem(playerEventsConstants.PARTNER_NAME)
    ) {
      partnerSource = localStorage.getItem(playerEventsConstants.PARTNER_NAME);
      partnerSource = JSON.parse(partnerSource);
    }
  } catch (error) {
    return playerEventsConstants.DEFAULT_PARTNER_ZEE5;
  }
  if (partnerSource && typeof partnerSource !== 'undefined') {
    return partnerSource;
  }
  return playerEventsConstants.DEFAULT_PARTNER_ZEE5;
};

export const getSelectedVideoQualityMixpanel = (selectedQuality: any) => {
  const videoQualitys = {
    Auto: videoQualityMixpanelOptions.Auto,
    'Auto - Recommended': videoQualityMixpanelOptions.Auto96p,
    'Data Saver - 240p': videoQualityMixpanelOptions.DataSaverUpto240p,
    'Data Saver': videoQualityMixpanelOptions.DataSaver,
    'SD - 480p': videoQualityMixpanelOptions.SDUpto480p,
    SD: videoQualityMixpanelOptions.SD,
    'HD - 720p': videoQualityMixpanelOptions.HDUpto720p,
    HD: videoQualityMixpanelOptions.HD,
    'Full HD - 1080p': videoQualityMixpanelOptions.FullHDUpto1080p,
    'Full HD': videoQualityMixpanelOptions.FullHD
  };
  const matchedValue = Object.keys(videoQualitys).find((key) =>
    key.match(selectedQuality)
  );
  return matchedValue || selectedQuality;
};

// To set a value in Session storage
export const setSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

// To get a value from Session storage
export const getSessionStorage = (key: string) => {
  let retrievedData = null;
  try {
    if (sessionStorage && sessionStorage.getItem(key)) {
      retrievedData = JSON.parse(sessionStorage.getItem(key) as any);
    }
  } catch (error: any) {
    console.log('Error:', error?.message);
  }

  return retrievedData;
};

export const getEncodedUri = (requestObj: any) => {
  let encodedString = '';
  // adds all the params even if the param doesnt have values
  Object.keys(requestObj).map((key, index) => {
    encodedString += `${key}=${requestObj[key]}`;
    if (index !== Object.keys(requestObj).length - 1) {
      encodedString += `&`;
    }
  });
  return encodedString;
};

export const fetchSelfErrors = (playerConfig: ZeePlayerConfig) => {
  let selfErrors = getLocalStorage('selfErrors');
  if (
    !selfErrors ||
    (selfErrors &&
      (typeof selfErrors === 'undefined' ||
        typeof selfErrors !== 'string' ||
        (typeof selfErrors === 'object' &&
          Object.keys(selfErrors).length === 0)))
  ) {
    playerConfig.selfErrorComponent();
  }
};

export const mapZeePlayerCodes = {
  1000: 'Z-4',
  1001: 'Z-4',
  1002: 'Z-4',
  1003: 'Z-3',
  1004: 'Z-4',
  1005: 'Z-7',
  2000: 'Z-7',
  2001: 'Z-7',
  3000: 'Z-3',
  3001: 'Z-4',
  3002: 'Z-3',
  3003: 'Z-3',
  3004: 'Z-7'
};

export const getUsersAge = (dob: string | undefined) => {
  if (dob) {
    return new Date().getFullYear() - new Date(dob).getFullYear();
  }
  return '';
};

/**
 *
 * @param obj1
 * @param obj2
 * @returns merged object. obj2 updates and add values to obj1
 */
export const mergeObjects = (obj1: any, obj2: any) => {
  let mergedObject: any = {};
  for (let key in obj1) {
    mergedObject[key] = obj1[key];
  }
  for (let key in obj2) {
    if (key in mergedObject) {
      if (
        typeof mergedObject[key] === 'object' &&
        typeof obj2[key] === 'object'
      ) {
        mergedObject[key] = mergeObjects(mergedObject[key], obj2[key]);
      } else {
        mergedObject[key] = obj2[key];
      }
    } else {
      mergedObject[key] = obj2[key];
    }
  }
  return mergedObject;
};

// watch History Changes
export const getLeftTime = (differenceTime: any) => {
  const totalMinutes = Math.floor(differenceTime / 60);
  const seconds = differenceTime % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (hours > 0) {
    return `${hours}h:${minutes}m left`;
  } else if (minutes > 0) {
    return `${minutes}m Left`;
  } else if (hours === 0 && minutes === 0) {
    return `${seconds}s left`;
  }
};
// watch History Changes

export const isIpadChrome = () => {
  const isiPad = navigator.userAgent.includes('iPad');
  const isChrome =
    navigator.userAgent.includes('Chrome') ||
    navigator.userAgent.includes('CriOS');
  return isiPad && isChrome;
};

export const getCookie = (key: any) => {
  if (typeof document === 'undefined') return null;
  const value = document.cookie;
  let finalVal: any = null;
  if (document.cookie && document.cookie.indexOf(key.trim()) > -1) {
    const parts = value && value.split(';');
    const checkVal =
      parts && parts.filter((part) => part.indexOf(key.trim()) > -1);
    finalVal = checkVal && checkVal.length && checkVal[0].split('=')[1];
    return finalVal;
  }
  return finalVal;
};

export const getLiveMatchData = (playerConfig: any) => {
  const url = `${playerConfig.nimbus_api}/scoreCard/widget?tournamentId=${playerConfig.ilt_tournament_id}&seasonId=${playerConfig.ilt_season_id}`;
  return url;
};

export const isB2BPartner = () => {
  if (
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    (window.location.pathname.includes('b2b-consumption') ||
      window.location.pathname.includes('b2b-live-tv'))
  ) {
    return true;
  }
  return false;
};
