export class AdUi {
  videoElement: Element;
  adContainerDiv: HTMLElement;
  controlsDiv: HTMLElement;
  countDownDiv: any;
  seekBarDiv: any;
  progressDiv: any;

  // constructor function
  constructor(videoElement: Element) {
    this.videoElement = videoElement;
    this.adContainerDiv = document.createElement('div');
    this.controlsDiv = document.createElement('div');
    this.countDownDiv = document.createElement('div');
    this.seekBarDiv = document.createElement('div');
    this.progressDiv = document.createElement('div');
    this.createContainer();
  }

  createContainer() {
    this.adContainerDiv.style.position = 'absolute';
    this.adContainerDiv.style.zIndex = '93';
    this.assignControlAttributes(this.adContainerDiv, 'zee-ad-container');
    this.videoElement.appendChild(this.adContainerDiv);
    this.createControls();
  }

  createControls() {
    this.assignControlAttributes(this.controlsDiv, 'zee-controls-div');
    this.controlsDiv.style.width = '100%';
    this.assignControlAttributes(this.countDownDiv, 'zee-countdown-div');
    this.controlsDiv.style.display = 'block';
    this.assignControlAttributes(this.seekBarDiv, 'zee-seek-bar-div');
    this.seekBarDiv.style.width = '100%';
    this.assignControlAttributes(this.progressDiv, 'zee-progress-div');
    this.adContainerDiv.appendChild(this.controlsDiv);
    this.controlsDiv.appendChild(this.countDownDiv);
    this.controlsDiv.appendChild(this.seekBarDiv);
    this.seekBarDiv.appendChild(this.progressDiv);
  }

  updateAdUi(
    currentTime: any,
    remainingTime: any,
    duration: any,
    adPosition: any,
    totalAds: any
  ) {
    // Update countdown timer data
    const remainingMinutes = Math.floor(remainingTime / 60);
    let remainingSeconds = Math.floor(remainingTime % 60).toString();
    if (remainingSeconds.toString().length < 2) {
      remainingSeconds = '0' + remainingSeconds;
    }
    let podCount = ': ';
    if (totalAds > 1) {
      podCount = ' (' + adPosition + ' ' + 'of' + ' ' + totalAds + '): ';
    }
    this.countDownDiv.innerHTML =
      'Advertisement' + podCount + remainingMinutes + ':' + remainingSeconds;

    // Update UI
    const playProgressRatio = currentTime / duration;
    const playProgressPercent = playProgressRatio * 100;
    this.progressDiv.style.width = playProgressPercent + '%';
  }

  // code will show ad ui container
  showAdContainer() {
    this.adContainerDiv.style.display = 'block';
  }

  // code will hide the ad ui container
  hideAdContainer() {
    this.adContainerDiv.style.display = 'none';
  }

  assignControlAttributes(element: Element, controlName: string) {
    element.id = controlName;
    element.className = controlName;
  }
}
