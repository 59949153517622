import { MPAdsMetadata } from './MPAdsMetadata';
import { USER_MAIL, USER_MOB } from '../constants/browserStorageConstants';
import { getLocalStorage } from '../utils/Utils';

export const InitializeAdsMetadata = (adEvent: { getAd: () => any }) => {
  console.log(adEvent);

  const ad = adEvent.getAd();
  let URL;
  console.log(ad);
  const notAvailableString = 'N/A';
  const technology =
    window && (window as any).is_dai ? 'server-side' : 'client-side';
  const isDai = (window && (window as any).is_dai) || false;

  if (ad && ad.j && ad.j.url) {
    URL = ad.j.url;
  } else if (ad && ad.j && ad.j.clickThroughUrl) {
    URL = ad.j.clickThroughUrl;
  }

  let adPosition;
  const imaPodIndex = ad.getAdPodInfo().getPodIndex();
  if (imaPodIndex === 0) {
    adPosition = 'Pre-roll';
  } else if (imaPodIndex === -1) {
    adPosition = 'Post-roll';
  } else {
    adPosition = 'Mid-roll';
  }

  const metaObj: MPAdsMetadata = {} as any;
  metaObj.title = ad.getTitle() || notAvailableString;
  metaObj.id = ad.getAdId() || notAvailableString;
  metaObj.url = URL || notAvailableString;
  metaObj.position =
    (ad.getAdPodInfo &&
      ad.getAdPodInfo() &&
      ad.getAdPodInfo().getAdPosition()) ||
    notAvailableString;
  metaObj.type = isDai ? 'Mid-roll' : adPosition;
  metaObj.adType =
    (ad.getContentType && ad.getContentType()) || notAvailableString;
  metaObj.system = (ad.getAdSystem && ad.getAdSystem()) || notAvailableString;
  metaObj.midRollPosition =
    adPosition && adPosition.toLowerCase() === 'mid-roll' && imaPodIndex
      ? imaPodIndex || notAvailableString
      : notAvailableString;
  metaObj.noAds =
    (ad.getAdPodInfo && ad.getAdPodInfo().getTotalAds()) || notAvailableString;
  metaObj.isAdPlayed = true;
  metaObj.isAllAdCompleted = false;
  metaObj.duration = (ad.getDuration && ad.getDuration()) || notAvailableString;
  metaObj.bitrate =
    (ad.getVastMediaBitrate && ad.getVastMediaBitrate()) || notAvailableString;
  metaObj.isSlate = false;
  metaObj.mediaFileApiFramework = ad.getApiFramework() || notAvailableString;
  metaObj.unitName = notAvailableString;
  metaObj.cueTime =
    (ad.getAdPodInfo && ad.getAdPodInfo().getTimeOffset()) ||
    notAvailableString;
  metaObj.sequence =
    (ad.getAdPodInfo && ad.getAdPodInfo().getAdPosition()) ||
    notAvailableString;
  metaObj.creativeId = ad.getCreativeId() || notAvailableString;
  metaObj.creativeName = ad.getDescription() || notAvailableString;
  metaObj.breakId = notAvailableString;
  metaObj.category = notAvailableString;
  metaObj.classification = notAvailableString;
  metaObj.advertiser = ad.getAdvertiserName() || notAvailableString;
  metaObj.advertiserCategory = notAvailableString;
  metaObj.advertiserId = notAvailableString;
  metaObj.campaignName = notAvailableString;
  metaObj.dayPart = notAvailableString;
  metaObj.managerVersion = notAvailableString;
  metaObj.sessionStartEvent = notAvailableString;
  metaObj.indiaexindia = notAvailableString;
  metaObj.technology = technology;
  metaObj.managerName = isDai ? 'dai' : 'ima';

  console.log(metaObj);
  return metaObj;
};

export const InitializeVmaxAdsMetadata = (ad: {
  [x: string]: any;
  adTitle: string;
  id: string;
  adClickThrough: string;
  pos: any;
  name: string;
  advertiserMarketer: string;
  advertiserId: any;
  campaignName: any;
}) => {
  const notAvailableString = 'N/A';
  let adPosition;
  const vmaxPodIndex = ad['cue-point'];
  if (vmaxPodIndex === 0) {
    adPosition = 'Pre-roll';
  } else if (vmaxPodIndex === -1) {
    adPosition = 'Post-roll';
  } else {
    adPosition = 'Mid-roll';
  }

  const metaObj: MPAdsMetadata = {} as any;
  metaObj.title = ad.adTitle || notAvailableString;
  metaObj.id = ad.id || notAvailableString;
  metaObj.url = ad.adClickThrough || notAvailableString;
  metaObj.position = ad.pos || notAvailableString;
  metaObj.type = adPosition;
  metaObj.adType = adPosition || notAvailableString;
  metaObj.system = notAvailableString;
  metaObj.midRollPosition =
    adPosition && adPosition.toLowerCase() === 'mid-roll' && vmaxPodIndex
      ? vmaxPodIndex || notAvailableString
      : notAvailableString;
  metaObj.noAds = notAvailableString;
  metaObj.isAdPlayed = true;
  metaObj.isAllAdCompleted = false;
  metaObj.duration = notAvailableString;
  metaObj.bitrate = notAvailableString;
  metaObj.isSlate = false;
  metaObj.mediaFileApiFramework = notAvailableString;
  metaObj.unitName = notAvailableString;
  metaObj.cueTime = notAvailableString;
  metaObj.sequence = notAvailableString;
  metaObj.creativeId = ad.id || notAvailableString;
  metaObj.creativeName = ad.name || notAvailableString;
  metaObj.breakId = notAvailableString;
  metaObj.category = notAvailableString;
  metaObj.classification = notAvailableString;
  metaObj.advertiser = ad.advertiserMarketer || notAvailableString;
  metaObj.advertiserCategory = notAvailableString;
  metaObj.advertiserId = ad.advertiserId || notAvailableString;
  metaObj.campaignName = ad.campaignName || notAvailableString;
  metaObj.dayPart = notAvailableString;
  metaObj.managerVersion = notAvailableString;
  metaObj.sessionStartEvent = notAvailableString;
  metaObj.indiaexindia = notAvailableString;
  metaObj.technology = 'client-side';
  metaObj.managerName = 'vmax';
  return metaObj;
};
export const isMobilePresentForLoggedIn = () => {
  try {
    const mobile = getLocalStorage(USER_MOB) ? getLocalStorage(USER_MOB) : '';
    return mobile.trim().length !== 0;
  } catch (error) {
    console.error('Failure to get user details from local storage');
  }
  return false;
};

export const isEmailPresentForLoggedIn = () => {
  try {
    const email = getLocalStorage(USER_MAIL) ? getLocalStorage(USER_MAIL) : '';
    return email.trim().length !== 0;
  } catch (error) {
    console.error('Failure to get user details from local storage');
  }
  return false;
};
