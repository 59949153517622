export class ConvivaAnalyticsTracker {
  // Conviva Functions
  public updateConvivaIntitialContent(assetInfo: any, showDetails: any): any {
    // const convivaAdditonalData = this.getConvivaAdditonalData(userData);
    (window as any).isConvivaSessionRequired = true;
    const playerAnalyticsObject =
      typeof window !== 'undefined'
        ? (window as any).playerAnalyticsObject
        : {};
    (window as any).convivaAnalytic.setInitialContentInfo(
      { ...assetInfo, ...playerAnalyticsObject },
      showDetails
    );
  }

  // Conviva Functions
  public parentalInititalContent(assetInfo: any, showDetails: any): any {
    // const convivaAdditonalData = this.getConvivaAdditonalData(userData);
    (window as any).isConvivaSessionRequired = true;
    const playerAnalyticsObject =
      typeof window !== 'undefined'
        ? (window as any).playerAnalyticsObject
        : {};
    (window as any).convivaAnalytic.setInitialContentInfo(
      { ...assetInfo, ...playerAnalyticsObject },
      showDetails
    );
  }

  public reportPlaybackEndedConviva(): any {
    (window as any).convivaAnalytic.reportEvent(
      (window as any).Conviva.Constants.PlayerState.STOPPED
    );
  }

  public updateConvivaInfo(metaData: any): any {
    if (window?.convivaAnalytic?.setContentInfo) {
      (window as any).convivaAnalytic.setContentInfo(metaData);
    }
  }

  public setConvivaAdAnalytics(videoPlayer: any, userType: string): any {
    try {
      const info: any = {};
      info[(window as any).Conviva.Constants.AD_TAG_URL] = 'adTagUrl';
      info[(window as any).Conviva.Constants.AD_PRELOAD_FEATURE] = true;
      info[(window as any).Conviva.Constants.IMASDK_CONTENT_PLAYER] =
        videoPlayer;
      if (
        window &&
        (window as any).adsLoader &&
        userType.toUpperCase() != 'PREMIUM'
      ) {
        (window as any).convivaAdAnalytics.setAdListener(
          (window as any).adsLoader,
          info
        );
        console.log('adLoader Object integrated to ConvivaAdSDK');
        (window as any).adsLoader = '';
      }
    } catch (error) {
      console.error('error adding videoplayer to Conviva AD SDK==', error);
    }
  }

  public getConvivaAdditonalData(userData: {
    user: { gender: string };
    age: string;
  }): any {
    const { user: { gender = 'Not-Received' } = {}, age = 'Not-Received' } =
      userData || {};
    return {
      userAge:
        age && age !== 'NA' && age !== 'Unknown'
          ? age.toString()
          : 'Not-Received',
      userGender:
        gender && gender !== 'NA' && gender === 'Unknown'
          ? 'Not-Received'
          : gender
    };
  }

  public getConvivaAdMetadata(ad: {
    j: { j: { j: any }[] };
    getTitle: () => any;
    getMediaUrl: () => any;
    getDuration: () => any;
    getWrapperAdIds: () => any[];
    getWrapperAdSystems: () => any[];
    getWrapperCreativeIds: () => any[];
    getAdSystem: () => any;
    getAdId: () => any;
    getCreativeId: () => any;
    getAdPodInfo: () => {
      (): any;
      new (): any;
      getPodIndex: { (): any; new (): any };
      getAdPosition: { (): any; new (): any };
    };
    getAdvertiserName: () => string;
    getDescription: () => string;
    getApiFramework: () => any;
  }): any {
    const adMetadata: any = {};
    const isDai = (window as any).is_dai || false;
    // ad asset metadata
    const streamUrl = (ad.j && ad.j.j && ad.j.j[0] && ad.j.j[0].j) || 'N/A';
    adMetadata[(window as any).Conviva.Constants.ASSET_NAME] = ad.getTitle();
    adMetadata[(window as any).Conviva.Constants.STREAM_URL] =
      (ad.getMediaUrl && ad.getMediaUrl()) || streamUrl;
    adMetadata[(window as any).Conviva.Constants.DURATION] =
      (ad.getDuration && ad.getDuration()) || 'N/A';
    adMetadata[(window as any).Conviva.Constants.IS_LIVE] =
      window && (window as any).isLive
        ? (window as any).Conviva.Constants.StreamType.LIVE
        : (window as any).Conviva.Constants.StreamType.VOD;
    adMetadata[(window as any).Conviva.Constants.ENCODED_FRAMERATE] = 30;
    // first wrapper info
    let firstAdSystem;
    let firstAdId;
    let firstCreativeId;
    if (ad.getWrapperAdIds() && ad.getWrapperAdIds().length !== 0) {
      const len = ad.getWrapperAdIds().length;
      firstAdSystem = ad.getWrapperAdSystems()[len - 1];
      firstAdId = ad.getWrapperAdIds()[len - 1];
      firstCreativeId = ad.getWrapperCreativeIds()[len - 1];
    } else {
      firstAdSystem = (ad.getAdSystem && ad.getAdSystem()) || 'N/A';
      firstAdId = (ad.getAdId && ad.getAdId()) || 'N/A';
      firstCreativeId = (ad.getCreativeId && ad.getCreativeId()) || 'N/A';
    }
    // get ad position based on https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/apis#ima.AdPodInfo.getPodIndex
    let adPosition;
    const imaPodIndex = ad.getAdPodInfo().getPodIndex();
    if (imaPodIndex === 0) {
      adPosition = 'Preroll';
    } else if (imaPodIndex === -1) {
      adPosition = 'Postroll';
    } else {
      adPosition = 'Midroll';
    }
    let advertiser = 'N/A';
    if (ad.getAdvertiserName) {
      advertiser = ad.getAdvertiserName();
    }
    let description = 'N/A';
    if (ad.getDescription) {
      description = ad.getDescription();
    }

    // ad tags
    adMetadata['c3.ad.id'] = (ad.getAdId() || 'N/A').toString();
    adMetadata['c3.ad.creativeId'] = (ad.getCreativeId() || 'N/A').toString();
    adMetadata['c3.ad.technology'] = isDai
      ? (window as any).Conviva.Constants.AdType.SERVER_SIDE
      : 'Client Side';
    adMetadata['c3.ad.system'] = (ad.getAdSystem() || 'N/A').toString();
    adMetadata['c3.ad.sequence'] = (
      ad.getAdPodInfo().getAdPosition() || 'N/A'
    ).toString();
    adMetadata['c3.ad.mediaFileApiFramework'] = (
      ad.getApiFramework() || 'N/A'
    ).toString();
    adMetadata['c3.ad.advertiser'] = advertiser || 'N/A';
    adMetadata['c3.ad.position'] = isDai
      ? (window as any).Conviva.Constants.AdPosition.MIDROLL
      : (window as any).Conviva.Constants.AdPosition[
          adPosition.toUpperCase()
        ] || 'NA';
    adMetadata['c3.ad.adManagerName'] = isDai ? 'dai' : 'ima';
    adMetadata['c3.ad.adManagerVersion'] =
      (window as any).google.ima.VERSION || 'N/A';
    adMetadata['c3.ad.appVersion'] = (window as any).appVersion || 'N/A';
    adMetadata['c3.ad.firstAdSystem'] = firstAdSystem || 'N/A';
    adMetadata['c3.ad.firstAdId'] = firstAdId || 'N/A';
    adMetadata['c3.ad.firstCreativeId'] = firstCreativeId || 'N/A';
    adMetadata['c3.ad.advertiserCategory'] = 'N/A';
    adMetadata['c3.ad.advertiserId'] = 'N/A';
    adMetadata['c3.ad.breakId'] = 'N/A';
    adMetadata['c3.ad.campaignName'] = 'N/A';
    adMetadata['c3.ad.adStitcher'] = isDai ? 'Google DAI' : 'ima';
    adMetadata['c3.ad.category'] = 'N/A';
    adMetadata['c3.ad.classification'] = 'N/A';
    adMetadata['c3.ad.creativeName'] = description || 'N/A';
    adMetadata['c3.ad.dayPart'] = 'N/A';
    adMetadata['c3.ad.sessionStartEvent'] = 'N/A';
    adMetadata['c3.ad.unitName'] = 'N/A';
    adMetadata['c3.ad.isSlate'] = 'false';
    adMetadata[(window as any).Conviva.Constants.FRAMEWORK_NAME] = isDai
      ? 'Google imadai'
      : 'Google ima';
    adMetadata[(window as any).Conviva.Constants.FRAMEWORK_VERSION] = (
      window as any
    ).google.ima.VERSION;
    adMetadata[(window as any).Conviva.Constants.DEFAULT_RESOURCE] = 'AMAZON';
    return adMetadata;
  }

  public adStartedEvent(adEvent: any) {
    const adMetadata = this.getConvivaAdMetadata(adEvent.getAd());
    (window as any).convivaAnalytic.reportEvent('adStarted', adMetadata);
    if (adEvent && adEvent.getAd) {
      const bitrate =
        adEvent.getAd().getVastMediaBitrate &&
        adEvent.getAd().getVastMediaBitrate()
          ? adEvent.getAd().getVastMediaBitrate()
          : 0;
      (window as any).convivaAnalytic.reportEvent('adBitrate', bitrate);
    }
    (window as any).convivaAnalytic.setContentInfo({
      adID: adMetadata['c3.ad.id']
    });
    (window as any).convivaAnalytic.setContentInfo({
      'c3.csid': adMetadata['c3.ad.id']
    });
    (window as any).convivaAnalytic.setContentInfo({
      adPosition: adMetadata['c3.ad.position']
    });
  }
  public adCompletedEvent() {
    (window as any).convivaAnalytic.reportEvent('adCompleted');
  }
  public adProgressEvent() {
    (window as any).convivaAnalytic.reportEvent('adProgress');
  }
  public adError(adError: any) {
    (window as any).convivaAnalytic.reportEvent('adError', adError);
  }
  public adBreakStart() {
    (window as any).convivaAnalytic.reportEvent('adBreakStart');
  }
  public adBreakEnd() {
    (window as any).convivaAnalytic.reportEvent('adBreakEnd');
  }
  public adSkipped() {
    (window as any).convivaAnalytic.reportEvent('adSkipped');
  }

  public playerProgress() {
    // window.convivaAnalytic.reportEvent(window.Conviva.Constants.PlayerState.PLAYING);
  }

  public onAdPaused() {
    (window as any).convivaAnalytic.reportEvent('adPaused');
  }

  public adLoaded() {
    if (!(window as any).isConvivaSessionRequired) {
      (window as any).isConvivaAdSessionRequired = true;
      (window as any).convivaAnalytic.setAdInitialInfo();
    }
  }

  public reportEvent(errorType: any, payload: any) {
    (window as any).convivaAnalytic.reportEvent(errorType, payload);
  }

  public reportErrorEvent(errorType: any, payload: any) {
    (window as any).convivaAnalytic.reportEvent(errorType, payload);
  }

  public createConvivaSessionOnSkip(playerRef: {
    singlePlayDetails: any;
    playerConfig: any;
  }) {
    const { singlePlayDetails = {}, playerConfig = {} } = playerRef || {};
    const {
      assetDetails = {},
      keyOsDetails = {},
      showDetails = {},
      tobaccoAdvisoryDetails = {}
    } = singlePlayDetails || {};
    if (window && (window as any).convivaAnalytic && (window as any).Conviva) {
      (window as any).convivaAnalytic.reportEvent(
        (window as any).Conviva.Constants.PlayerState.STOPPED
      );
      this.updateConvivaIntitialContent(
        { ...assetDetails, ...keyOsDetails, ...tobaccoAdvisoryDetails },
        showDetails
      );
    }
  }

  public createConvivaSessionParental(playerRef: {
    singlePlayDetails: any;
    playerConfig: any;
  }) {
    const { singlePlayDetails = {}, playerConfig = {} } = playerRef || {};
    const {
      assetDetails = {},
      keyOsDetails = {},
      showDetails = {}
    } = singlePlayDetails || {};
    if (window && (window as any).convivaAnalytic && (window as any).Conviva) {
      (window as any).convivaAnalytic.reportEvent(
        (window as any).Conviva.Constants.PlayerState.STOPPED
      );
      this.parentalInititalContent(
        { ...assetDetails, ...keyOsDetails },
        showDetails
      );
      this.convivaSessionWaitStarted();
    }
  }

  public convivaSessionWaitStarted() {
    (window as any).convivaAnalytic.reportEvent(
      (window as any).Conviva.Constants.Events.USER_WAIT_STARTED
    );
  }

  public convivaSessionWaitEnded(videoPlayer: any) {
    (window as any).parentalPinPopUpEnabled = undefined;
    (window as any).parentalPinIncorrect = undefined;
    (window as any).convivaSDK.setPlayer(videoPlayer);
    (window as any).convivaAnalytic.reportEvent(
      (window as any).Conviva.Constants.Events.USER_WAIT_ENDED
    );
  }

  public convivaDrmSessionWaitStarted(videoPlayer: any) {
    (window as any).convivaSDK.setPlayer(videoPlayer);
    (window as any).convivaAnalytic.reportEvent(
      (window as any).Conviva.Constants.Events.USER_WAIT_ENDED
    );
  }

  public updateBufferPlayHeadTime() {
    (window as any).convivaAnalytic.reportEvent('updateBufferPlayHeadTime');
  }
}
