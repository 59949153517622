import { DAIConfig } from './DAIConfig';
import { IMAConfig } from './IMAConfig';
import { VMAXConfig } from './VMAXConfig';

// ad client enumations
export enum AdClient {
  DAI = 'DAI',
  IMA = 'IMA',
  VMAX = 'VMAX'
}
// interface for all ad related configuration
export interface AdConfig {
  client: AdClient;
  imaSettings?: IMAConfig;
  daiSettings?: DAIConfig;
  vmaxSettings?: VMAXConfig;
}
