export function adjustPinScorecardContainerOnChromecast(add = false) {
  const pinScorecardContainer =
    document.getElementsByClassName('scoreMainContainer')?.[0];
  const pinScorecardContainerClassList = pinScorecardContainer?.classList;

  // if (pinScorecardContainerClassList && pinScorecardContainerClassList.length) {
  add
    ? pinScorecardContainerClassList?.add('pinScorecardContainerOnChromecast')
    : pinScorecardContainerClassList?.remove(
        'pinScorecardContainerOnChromecast'
      );
  // }
}
