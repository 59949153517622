// import './AdCuePoints.css';

import { SpApiResponse } from '../configs/SpApiResponse';

export function adCuePoints(playerRef: any, singlePlayData: SpApiResponse) {
  const videoPlayer = playerRef;
  const singlePlayDetails = singlePlayData;
  const { adDetails = [] } = singlePlayDetails || {};
  const { assetDetails = [] } = singlePlayDetails || {};
  const { adMarkers = [] } = { ...assetDetails } || {};
  const { video_ads = [] } = { ...adDetails } || {};
  const {
    ads_visibility = false,
    intervals = [],
    adsUrl = ''
  } = video_ads[0] || {};
  const progressBar = document.getElementsByClassName(
    'vjs-progress-holder vjs-slider vjs-slider-horizontal'
  )[0];
  const totalDuration = videoPlayer && videoPlayer.duration();
  const checkAdTag = document.querySelector('div [playerAdTag=ad]');
  let advetisementTime = intervals;
  if (ads_visibility && adsUrl && !intervals.length && adMarkers.length) {
    advetisementTime = adMarkers;
  }
  if (
    !checkAdTag &&
    !isNaN(totalDuration) &&
    progressBar &&
    ads_visibility &&
    advetisementTime.length
  ) {
    const adDiv = document.createElement('div');
    adDiv.setAttribute('playerAdTag', 'ad');
    progressBar.appendChild(adDiv);
    if (adMarkers) {
      adMarkers.map((time) => {
        const adTime = time;
        const position = (Number(adTime) * 100) / totalDuration;
        const adTag = document.createElement('div');
        adTag.setAttribute(
          'style',
          `position:absolute;width:5px;height:100%;background-color:rgb(246, 184, 26);left:${position}%;`
        );
        adDiv.appendChild(adTag);
      });
    } else {
      advetisementTime.map((ad) => {
        const adTime = ad.time;
        if (!ad.isAdPlayed) {
          const position = (Number(adTime) * 100) / totalDuration;
          const adTag = document.createElement('div');
          adTag.setAttribute(
            'style',
            `position:absolute;width:5px;height:100%;background-color:rgb(246, 184, 26);left:${position}%;`
          );
          adDiv.appendChild(adTag);
        }
      });
    }
  } else if (
    checkAdTag &&
    (!advetisementTime || (advetisementTime && !advetisementTime.length))
  ) {
    checkAdTag.remove();
  }
}
