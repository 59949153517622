import videojs from 'video.js';

export function addGoLiveButton(
  playerRef: any,
  goLiveCallback: { (): void; (): void }
) {
  const videoPlayer = playerRef;
  const Button = videojs.getComponent('Button');
  const obj: Object = {
    clickHandler: () => {
      goLiveCallback();
    }
  };
  const goLiveButton = new Button(videoPlayer, obj);
  goLiveButton.addClass('go-live-button');
  goLiveButton.contentEl().innerHTML = 'Go Live';
  // playerRef.controlBar.addChild(goLiveButton);
  const controlBar = videoPlayer.controlBar,
    audioButton = videoPlayer.controlBar.getChild('AudioTrackButton').el();
  controlBar
    .el()
    .insertBefore(controlBar.addChild(goLiveButton).el(), audioButton);
}
