import { VideoJsPlayer } from 'video.js';

import { CastHelper } from './CastHelper';
import { AdsManager } from '../advertisement/AdsManager';
import { AnalyticsManager } from '../analytics/AnalyticsManager';
import { castEventsConstants } from '../constants/playerConstants';

export class CastSender {
  videoPlayer?: VideoJsPlayer;
  adsManager?: AdsManager;
  context?: cast.framework.CastContext;
  remotePlayer?: cast.framework.RemotePlayer;
  remotePlayerController?: cast.framework.RemotePlayerController;
  castHelper?: CastHelper;
  castSession?: any;
  analyticsManager?: AnalyticsManager;

  constructor(videoPlayer: VideoJsPlayer, adsManager: AdsManager) {
    this.videoPlayer = videoPlayer;
    this.adsManager = adsManager;
    this.analyticsManager = AnalyticsManager.getInstance();
    if (window.isCastAvailable) {
      this.initializeCastApi();
    }
  }

  // code will initialize the cast apis
  private initializeCastApi() {
    cast &&
      cast.framework &&
      cast.framework.setLoggerLevel &&
      cast.framework.setLoggerLevel(cast.framework.LoggerLevel.DEBUG);
    this.context =
      cast &&
      cast.framework &&
      cast.framework.CastContext &&
      cast.framework.CastContext.getInstance &&
      cast.framework.CastContext.getInstance();
    this.remotePlayer =
      cast &&
      cast.framework &&
      cast.framework.RemotePlayer &&
      new cast.framework.RemotePlayer();
    if (this.remotePlayer) {
      this.remotePlayerController =
        cast?.framework?.RemotePlayerController &&
        new cast.framework.RemotePlayerController(this.remotePlayer);
      this.context.addEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        this.initializeCastSession.bind(this)
      );
      this.context.addEventListener(
        cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        this.onSessionStateChange.bind(this)
      );
      this.remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
        this.onMediaLoadChangeListener.bind(this)
      );
      this.remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        this.onConnectedChangeListener.bind(this)
      );
    }
  }

  async initializeCastSession({ castState = '' }) {
    this.castHelper = new CastHelper(
      this.videoPlayer as VideoJsPlayer,
      this.remotePlayer as cast.framework.RemotePlayer,
      this.remotePlayerController as cast.framework.RemotePlayerController
    );
    if (castState === 'CONNECTED') {
      this.adsManager?.destroy();
      const adContainer = document.querySelector('#video-tag_ima-ad-container');
      const videoEL = this.videoPlayer && this.videoPlayer.el();
      if (adContainer) {
        videoEL?.removeChild(adContainer);
      }
      const customData = await this.castHelper.getChromecastCustomData(
        this.videoPlayer
      );
      this.castSession =
        cast.framework.CastContext.getInstance().getCurrentSession();
      if (chrome && chrome.cast && chrome.cast.media) {
        const mediaInfo = new chrome.cast.media.MediaInfo();
        const request = new chrome.cast.media.LoadRequest(mediaInfo);
        mediaInfo.customData = customData;
        this.videoPlayer?.pause();
        request.currentTime = this.videoPlayer?.currentTime() as number;
        this.castSession &&
          this.castSession.loadMedia(request).then(
            () => {
              this.castHelper && this.castHelper.enableCastMode();
              this.analyticsManager?.reportEvent(
                castEventsConstants.CAST_STARTED,
                {}
              );
            },
            (errorCode: Promise<chrome.cast.ErrorCode | undefined>) => {
              console.log('Cast Failed due to : ', errorCode);
              this.castHelper && this.castHelper.castLoadMediaFailed();
              this.analyticsManager?.reportEvent(
                castEventsConstants.CAST_FAILED,
                errorCode
              );
            }
          );
      }
    }
  }

  // session state change listener function
  private onSessionStateChange(event: any) {
    switch (event.sessionState) {
      case cast.framework.SessionState.SESSION_STARTED:
        console.log('CastContext: CastSession started');
        break;
      case cast.framework.SessionState.SESSION_RESUMED:
        console.log('CastContext: CastSession resumed');
        break;
      case cast.framework.SessionState.SESSION_ENDED:
        console.log('CastContext: CastSession disconnected');
        break;
    }
  }

  // media info change listener function
  private onMediaLoadChangeListener() {
    const session =
      cast.framework.CastContext.getInstance().getCurrentSession();
    if (session && this.remotePlayer) {
      this.remotePlayer.currentTime = this.videoPlayer?.currentTime() as number;
      this.remotePlayerController?.seek();
    } else {
      return;
    }
  }

  // code will listen for disconnection
  private onConnectedChangeListener() {
    if (!(this.remotePlayer && this.remotePlayer.isConnected)) {
      this.castSession && this.castSession.endSession(true);
      window['isCastConnected'] = false;
      this.castHelper?.stopCasting();
      this.analyticsManager?.reportEvent(castEventsConstants.CAST_ENDED, {});
      console.log('RemotePlayerController: Player disconnected');
    }
  }
}
