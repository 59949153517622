import {
  getLocalStorageInteger,
  convertToSeconds,
  isNotTvodContent,
  getCookieData,
  getUserId,
  getLocalStorage,
  getLanguageName,
  setLocalStorage,
  getSelectedVideoQualityMixpanel,
  isMobileDevice,
  isTataSkyUser,
  mapZeePlayerCodes,
  getUsersAge,
  getSessionStorage,
  getLeftTime,
  convertSecsToTime,
  getCookie,
  getLiveMatchData
} from './Utils';
import { AnalyticsManager } from '../analytics/AnalyticsManager';
import { ConvivaAnalyticsTracker } from '../analytics/ConvivaAnalyticsTracker';
import { KeyMomentsConvivaAnalyticsTracker } from '../analytics/KeyMomentsConvivaAnalyticsTracker';
import { addSkipIntroButton } from '../components/SkipIntroButton';
import { watchCredits } from '../components/WatchCreditsButton';
import { showTrailerInfo } from '../components/TrailerInfo';
import { ErrorCode } from '../error/Error';
import {
  showTobaccoAdvisory,
  removeTobaccoAdvisory
} from '../components/TobaccoAdvisory';
import {
  commerceConstants,
  constants,
  playerEventsConstants
} from '../constants/playerConstants';
// import { useAppStore } from 'src/store/CreateStore';
import {
  adjustShopIconContainerOnChromecast,
  initialCommerceIcon
} from '../components/initialCommerceIcon';
// import demoData from '../utils/demoData.json'
// import { httpObject } from '../../../../services/fetch/HttpService';
// import { HTTP_METHODS } from '../../../../utils/constants/HttpMethodsConstants';
// import { clearCtaEventsProperty } from 'src/utils/analytics/mixpanel/mixpanelHelper';
// import {
//   getPageName,
//   mixpanelConstants,
//   mixpanelObject
// } from 'src/utils/analytics';
// import { widgetCTAEvent } from 'src/utils/analytics/mixpanel/mixpanelEventsHelper';
// import { useAppStore } from 'src/store/CreateStore';
import { UserWatch } from './PlayerConfig';
import { adjustPinScorecardContainerOnChromecast } from '../components/initialPinScorecard';
import { FeatureFlagConstant } from './FeatureFlagConstants';

let firstTimeRender = true;

let liveStatus: any;
let currentAPIcheck: any;
// let watchCreditsConstructedFlag = false;

export let intervalTimerRefForPinScoreCard: NodeJS.Timeout;

// Added temporarily will remove
let isFullScreenFlag = false;
let firstPlayHappened = false;
let playerRef: any = {};
let convivaAnalyticsTracker: any;
let upNextStartInTime: any = 0;
let upNextApiTime: any = 0;
const watchTime: any = 0;
let watchCreditsStartTime: any = 0;
let watchCreditsTime: any = 0;
let upNextCarouselState = false;
let upNextApiState = false;
let isUpNextPending = false;
let counterStartTime: any = 5;
let continousplayTime: any = null;
let startScrollTime: any = 0;
let startScroll: any = 0;
let scrollTimeout = false;
let analyticsManager: any;
let isContentResumed = false;
let playerTimestamp: any;
let isStaleApisCalled: boolean;
let prevCapping: any = null;
let isPopupVideo = false;
let oldAssetId: string;
let lcOldAssetId: string;
let firstTimeRenderForCommerce = true;
let oneTimeFnRenderInPlayEvent = true;
let oneTimeFnRenderInCurrentTime = true;
let commerceFeatureFlagInPlayEvent = false;
let commerceFeatureFlagInCurrentTime = false;
let b2bUpnextFlag = true;
let midTobaccoAdvisoryVideoShown = false;
// let validContentTypesForStaleApi: string[];

// Player callback events
export function registerEvents(playerReference: any, isKeyMoments = false) {
  // assigning playerReference to local reference to update with new data if any from FE
  playerRef = playerReference;
  firstPlayHappened = false;
  watchCreditsTime = 0;
  if (isKeyMoments) {
    convivaAnalyticsTracker = new KeyMomentsConvivaAnalyticsTracker();
    analyticsManager = AnalyticsManager.getInstance();
  } else {
    convivaAnalyticsTracker = new ConvivaAnalyticsTracker();
    analyticsManager = AnalyticsManager.getInstance();
  }
  isContentResumed = false;
  playerTimestamp = new Date();
  isStaleApisCalled = false;
  // validContentTypesForStaleApi = ['movie', 'episode'];
}

export function checkOfferJsonDataForCommerceFlag() {
  return playerRef?.playerConfig?.isFeatureEnabled(
    FeatureFlagConstant.CONFIG_COMMERCE
  );
}

export function checkIsCommerceAsset(assetId = '') {
  const { enabled, payload } = playerRef?.playerConfig?.getFeatureFlagConfig(
    FeatureFlagConstant.CONFIG_COMMERCE
  );
  const commerceAssets = enabled ? payload['commerce_assets'] : [];
  return commerceAssets.includes(assetId);
}

export function detectChromeCastForShopIcon() {
  const isChromeCastAvailable = document.querySelector('.chromeCastIcon');
  if (isChromeCastAvailable) {
    adjustShopIconContainerOnChromecast(true);
  } else {
    adjustShopIconContainerOnChromecast(false);
  }
}

export function detectChromeCast() {
  const isChromeCastAvailable = document.querySelector(
    '.chromeCastBtnContainer'
  );
  if (isChromeCastAvailable) {
    adjustPinScorecardContainerOnChromecast(true);
  } else {
    adjustPinScorecardContainerOnChromecast(false);
  }
}

export const handlePinScoreCardMixpanel = () => {
  analyticsManager.reportEvent(playerEventsConstants.PIN_SCOREBOARD, null);
};

export const displayScoreCard = async (playerConfig: any) => {
  const { createScoreContainer = null, removeScorecardWidget = null } =
    playerConfig || {};
  const { payload: sportsConfig } =
    playerConfig.getFeatureFlagConfig('config_sports') || {};

  const pinnedScorecardConfigFlag = sportsConfig?.pinnedScorecard_flag;
  const { isTrailer = 0 } = playerRef?.singlePlayDetails?.entitlement;
  const { userType = 'guest', pinScoreILT_flag = false } = playerConfig;

  if (
    createScoreContainer &&
    pinnedScorecardConfigFlag &&
    !isTrailer &&
    userType?.toLowerCase() !== 'premium' &&
    pinScoreILT_flag &&
    playerConfig.mixpanelData.pageName !== 'ILT20 ConsumptionPage'
  ) {
    let response: any;
    const xAccessToken = getCookie('xaccesstoken') || '';
    let url = getLiveMatchData(playerConfig);
    try {
      const data = await fetch(url, {
        headers: { 'x-access-token': xAccessToken }
      });
      if (data?.status === 200 && !data?.json) {
        removeScorecardWidget();
        clearInterval(intervalTimerRefForPinScoreCard);
        return;
      }
      const scoreCard = await data.json();
      response = scoreCard;
      currentAPIcheck = true;
    } catch (err) {
      currentAPIcheck = false;
    }

    liveStatus = response?.status?.toLowerCase();
    if (firstTimeRender) {
      onReadyEventHelper(playerConfig);
      firstTimeRender = false;
    }
    if (response?.status?.toLowerCase() === 'live') {
      const payload = {
        teamA: response?.battingTeamDetail?.shortName,
        scoreA: response?.battingTeamDetail?.scores,
        oversA: response?.battingTeamDetail?.overs,
        teamB: response?.bowlingTeamDetail?.shortName,
        scoreB: response?.bowlingTeamDetail?.scores,
        oversB: response?.bowlingTeamDetail?.overs,
        matchId: response?.matchId,
        tournamentId: response?.tournamentId,
        title: response?.shortTitle?.replaceAll(' ', '').toLowerCase(), //title will be auto corrected
        currentAPIcheck: currentAPIcheck
      };
      if (
        !!payload.teamA &&
        !!payload.scoreA &&
        !!payload.oversA &&
        !!payload.matchId &&
        !!payload.tournamentId &&
        !!payload.title &&
        !!payload.currentAPIcheck
      ) {
        createScoreContainer(payload);
      }
    } else {
      removeScorecardWidget();
      clearInterval(intervalTimerRefForPinScoreCard);
    }
  }
};

export async function onReadyEventHelper(playerConfig: any) {
  const { payload: sportsConfig } =
    playerConfig.getFeatureFlagConfig('config_sports') || {};

  const pinnedScorecardConfig = sportsConfig?.pinnedScorecard_interval;
  const pinnedScorecardConfigFlag = sportsConfig?.pinnedScorecard_flag;
  const { isTrailer = 0 } = playerRef?.singlePlayDetails?.entitlement;
  const { userType = 'guest', pinScoreILT_flag = false } = playerConfig;

  if (
    pinnedScorecardConfigFlag &&
    liveStatus?.toLowerCase() === 'live' &&
    !isTrailer &&
    userType?.toLowerCase() !== 'premium' &&
    pinScoreILT_flag &&
    playerConfig.mixpanelData.pageName !== 'ILT20 ConsumptionPage'
  ) {
    handlePinScoreCardMixpanel();
    firstTimeRender = false;
    intervalTimerRefForPinScoreCard = setInterval(async () => {
      displayScoreCard(playerConfig);
    }, pinnedScorecardConfig * 1000);
  }
}

export async function onPlayEventHelper(
  videoPlayer: any,
  playedInPopUp = false,
  isAdStacksDataAvailable = false
) {
  if (oneTimeFnRenderInPlayEvent) {
    commerceFeatureFlagInPlayEvent = checkOfferJsonDataForCommerceFlag();
    oneTimeFnRenderInPlayEvent = false;
  }
  let shopIcon = document.querySelector('.initialShopIconContainer');
  const {
    singlePlayDetails: {
      assetDetails: { asset_subtype = '', id = '' } = {}
    } = {}
  } = playerRef || {};
  if (
    commerceFeatureFlagInPlayEvent &&
    checkIsCommerceAsset(id) &&
    isAdStacksDataAvailable &&
    !shopIcon &&
    firstTimeRenderForCommerce &&
    asset_subtype === 'movie'
  ) {
    initialCommerceIcon();
    firstTimeRenderForCommerce = false;
  }
  detectChromeCastForShopIcon();
  if (!firstPlayHappened) {
    firstPlayHappened = true;
    upNextApiState = false;
    b2bUpnextFlag = true;
    const {
      singlePlayDetails = {},
      playerConfig = {},
      playableData = {}
    } = playerRef || {};
    const { assetDetails: { tags = [] } = {} } = singlePlayDetails || {};
    const {
      userType = 'guest',
      removeEduauraaMessage = null,
      createEduauraaMessage = null,
      clevertapEventPush = null
    } = playerConfig || {};
    const { end_credits_start_s = '' } = playableData || {};
    const { messages = {} } = (window as any).translationsAndConfigs || {};
    if (!playedInPopUp) {
      (window as any).videoPlayer = videoPlayer;
      isPopupVideo = false;
    } else {
      isPopupVideo = true;
    }
    playerTimestamp = new Date();
    try {
      (window as any).convivaSDK.setPlayer(videoPlayer);
      console.log('PlayerEvent, VideoPlayer Object integrated to ConvivaSDK');
    } catch (error) {
      console.error(
        'PlayerEvent, Error while adding video Player to conviva SDK',
        error
      );
    }
    convivaAnalyticsTracker.setConvivaAdAnalytics(videoPlayer, userType);
    // to destroy up next carousel
    if (upNextCarouselState) {
      destroyUpNextCarousel();
      upNextCarouselState = false;
    }
    if (tags && tags.includes('Eduauraa') && createEduauraaMessage) {
      createEduauraaMessage();
    } else if (removeEduauraaMessage) {
      removeEduauraaMessage();
    }
    if (end_credits_start_s) {
      watchCreditsTime = convertToSeconds(end_credits_start_s);
      upNextStartInTime = Math.round(videoPlayer.duration());
      -messages.duration;
    }
    if (
      playableData.asset_subtype == 'trailer' ||
      playableData.asset_subtype == 'promo'
    ) {
      showTrailerInfo();
    }

    // Create Chromecast button
    if (
      (window as any).isCastAvailable &&
      singlePlayDetails.assetDetails.is_casting_enabled &&
      !document.querySelector('.chromeCastBtnContainer')
    ) {
      createChromeCastButton(videoPlayer);
      fillColorToCastIcon();
    }
    invokeTeaserBanner();
    resumeCovivaSession(videoPlayer);
    (window as any).parentalPinIncorrect = undefined;
    (window as any).parentalPinPopUpEnabled = undefined;
    analyticsManager.setMixpanelVideoPlayer(videoPlayer);
    analyticsManager.setClevertapEventPushRef(clevertapEventPush);
    // if (preRollDetectedorFailed) {
    reportVideoViewEvent(singlePlayDetails.assetDetails.id);
    // }
    if (isContentResumed) {
      analyticsManager.reportEvent(playerEventsConstants.onReplay, null);
      isContentResumed = false;
    }
    (window as any).contentExit = watchDurationCaluculator;
    try {
      const oldVolumeLevel = getLocalStorage('oldVolumeLevel') || '1';
      videoPlayer.volume(oldVolumeLevel);
      const muteStatus = getLocalStorage('oldMutedStatus') || false;
      if (muteStatus && muteStatus == 'true') {
        videoPlayer.muted(true);
      }
    } catch (error) {
      console.error('error in updating previous volume and mute status');
    }
    let planId = sessionStorage.getItem(playerEventsConstants.PLAN_ID);
    planId =
      planId && typeof planId !== 'undefined'
        ? JSON.parse(planId)
        : playerEventsConstants.Not_Applicable;
    const planIdPayload: any = {};
    planIdPayload['c3.cm.planId'] = planId;
    convivaAnalyticsTracker.updateConvivaInfo(planIdPayload);
    if (
      playerConfig.isSportsLiveEvent({
        assetType: singlePlayDetails.assetDetails.asset_type,
        assetSubType: singlePlayDetails.assetDetails.asset_subtype
      })
    ) {
      setTimeout(() => {
        if (!(window as any).videoPlayer.liveTracker.atLiveEdge()) {
          (window as any).videoPlayer.liveTracker.seekToLiveEdge();
        }
      }, 3000);
    }
  }
  // Play event handling only
  if (window && (window as any).chromePopupDisplayed) {
    videoPlayer.pause();
  }
  analyticsManager.reportEvent(playerEventsConstants.playEvent, null);
  // end play event handling
}
//todo playerEvents helper

export function reportVideoViewEvent(assetId: string) {
  const autoPlay = sessionStorage.getItem('AutoPlay') || 'Default';
  const freeMinutesEndedContainer = document.querySelector(
    '.freeMinutesEndedPopup'
  );
  if (!freeMinutesEndedContainer && (!oldAssetId || oldAssetId !== assetId)) {
    analyticsManager.reportEvent(playerEventsConstants.firstPlay, { autoPlay });
  }
  oldAssetId = assetId;
}

export function ifAssetIdChanges(lcAssetId: string) {
  if (!lcOldAssetId || lcOldAssetId !== lcAssetId) {
    setLocalStorage('languageChangeTriggered', 'false');
  }
  lcOldAssetId = lcAssetId;
}

export function checkIfTobaccoAdvisory(
  singlePlayDetails: any,
  tobaccoAdvisoryConfig: any
) {
  if (
    (singlePlayDetails?.tobaccoAdvisoryDetails?.video_segments &&
      tobaccoAdvisoryConfig?.video_segment_config?.enable) ||
    (singlePlayDetails?.tobaccoAdvisoryDetails?.aston_segments &&
      tobaccoAdvisoryConfig?.aston_band_config?.enable)
  ) {
    return true;
  }
  return false;
}

export function getTotalAstonBands(singlePlayDetails: any) {
  return singlePlayDetails?.tobaccoAdvisoryDetails?.aston_segments.length;
}

function createChromeCastButton(videoPlayer: any) {
  const castHolder = document.createElement('div');
  const castButton = document.createElement('google-cast-launcher');
  castButton.setAttribute('class', 'chromeCastIcon');
  castHolder.setAttribute('title', 'Cast');
  castHolder.setAttribute('style', '--disconnected-color: #4285f4;');
  castHolder.setAttribute('id', 'chromeCastBtnContainer');
  castHolder.className = 'chromeCastBtnContainer';
  castHolder.appendChild(castButton);
  const videoEL = videoPlayer && videoPlayer.el();
  videoEL && videoEL.appendChild(castHolder);
}

export function createLanguageSelectionUnit(videoPlayer: any) {
  const { playerConfig = {}, singlePlayDetails = {} } = playerRef || {};
  const { getNativeStringByLangCode = null } = playerConfig || {};
  const {
    assetDetails: { audio_languages: audioLanguages = [] } = {},
    keyOsDetails: { stream_language: defaultStreamLanguage = 'en' } = {}
  } = singlePlayDetails || {};
  const { audioSelectionTimeout } = constants;

  const audioSelectionDiv = document.createElement('div');
  audioSelectionDiv.className = 'audio-selection-wrapper';
  audioSelectionDiv.style.pointerEvents = 'none';
  const changeLangTitleDiv = document.createElement('div');
  changeLangTitleDiv.className = 'audio-selection-title';
  changeLangTitleDiv.innerHTML = 'Choose Your Language';
  const audioLanguagesDiv = document.createElement('div');
  audioLanguagesDiv.className = 'audio-selection-list';
  audioLanguagesDiv.style.pointerEvents = 'auto';

  audioLanguages.forEach((item: any, index: any) => {
    const languageObj = getNativeStringByLangCode(item);
    const audioLangItem = document.createElement('span');
    audioLangItem.style.pointerEvents = 'auto';
    audioLangItem.className = `audio-stream-item audio-${item} ${
      defaultStreamLanguage === item
        ? 'activeAudioLang'
        : defaultStreamLanguage
        ? ''
        : index === 0
        ? 'activeAudioLang'
        : ''
    }`;
    audioLangItem.setAttribute('key', `${item}`);
    audioLangItem.innerHTML = languageObj.native;

    const tickMarkActiveSelection = document.createElement('span');
    tickMarkActiveSelection.className = 'tick iconOther-ic_tick';
    if (audioLangItem.classList.contains('activeAudioLang')) {
      audioLangItem.appendChild(tickMarkActiveSelection);
    }

    // Adding click listeners and adding active class to selected element
    audioLangItem.addEventListener('click', function () {
      const current = document.getElementsByClassName('activeAudioLang');
      current[0].className = current[0].className.replace(
        ' activeAudioLang',
        ''
      );
      // @ts-ignore
      this.classList += ' activeAudioLang';
      setLocalStorage('prefferedAudioStream', item);
      audioChange(audioLanguages[index], true, defaultStreamLanguage);
    });
    audioLanguagesDiv.appendChild(audioLangItem);
  });
  audioSelectionDiv.appendChild(changeLangTitleDiv);
  audioSelectionDiv.appendChild(audioLanguagesDiv);
  const videoEL = videoPlayer && videoPlayer.el();
  videoEL && videoEL.appendChild(audioSelectionDiv);

  setTimeout(() => {
    removeLanguageSelectionUnit(videoPlayer);
  }, audioSelectionTimeout);
}

function removeLanguageSelectionUnit(videoPlayer: any) {
  const audioSelectionDiv = document.querySelector('.audio-selection-wrapper');
  const videoEL = videoPlayer && videoPlayer.el();
  videoEL && videoEL.removeChild(audioSelectionDiv);
}

function checkForSpecialPlan() {
  return (
    (window as any).specialPlan &&
    Object.keys((window as any).specialPlan).length
  );
}

function invokeTeaserBanner() {
  const { playerConfig = {}, isAdPlaying = false } = playerRef || {};
  const { triggerTeaserBanner = null, singlePlayDetails } = playerConfig || {};
  const isPlayerOverlayNudgeVisible = document.querySelector(
    '.subscription-nudge-container'
  );
  if (
    singlePlayDetails?.assetDetails?.tier &&
    !isAdPlaying &&
    !isPlayerOverlayNudgeVisible
  ) {
    triggerTeaserBanner({ playerRef });
  }
  if (checkForSpecialPlan() && !isAdPlaying && !isPlayerOverlayNudgeVisible) {
    triggerTeaserBanner({ playerRef });
  } else {
    const interval = setInterval(() => {
      if (
        checkForSpecialPlan() &&
        !isAdPlaying &&
        !isPlayerOverlayNudgeVisible
      ) {
        clearInterval(interval);
        triggerTeaserBanner({ playerRef });
      }
    }, 500);
  }
}

function getConvivaSessionId() {
  try {
    const convivaID =
      window &&
      (window as any).convivaSDK &&
      (window as any).convivaSDK.getSessionId
        ? (window as any).convivaSDK.getSessionId().toString()
        : 'N/A';
    return convivaID.replace(/-/g, '');
  } catch (error) {
    return 'NA';
  }
}

function fillColorToCastIcon() {
  const castIcon = document.querySelector('google-cast-launcher');
  if (
    castIcon &&
    castIcon.shadowRoot &&
    castIcon.shadowRoot.querySelectorAll('path')
  ) {
    castIcon.shadowRoot
      .querySelectorAll('path')
      .forEach((element) => (element.style.fill = '#4285f4'));
  }
}

export function setConvivaInitialConentKeyMoments(
  singlePlayDetails: any,
  userData: any,
  videoPlayer: any
) {
  const { keyOsDetails = {}, showDetails = {} } = singlePlayDetails || {};
  const { entitlement: { isTrailer = 0 } = {}, trailerDetails = {} } =
    singlePlayDetails || {};
  const assetDetails = !isTrailer
    ? singlePlayDetails.assetDetails
    : trailerDetails;
  if (
    window &&
    (window as any).keyMomentsconvivaAnalytic &&
    (window as any).Conviva
  ) {
    convivaAnalyticsTracker.updateConvivaIntitialContent(
      { ...assetDetails, ...keyOsDetails },
      showDetails,
      userData,
      videoPlayer
    );
  }
}

export function setConvivaInitialConent(
  singlePlayDetails: any,
  userData: any,
  videoPlayer: any
) {
  const {
    keyOsDetails = {},
    showDetails = {},
    tobaccoAdvisoryDetails = {}
  } = singlePlayDetails || {};
  const { entitlement: { isTrailer = 0 } = {}, trailerDetails = {} } =
    singlePlayDetails || {};
  const assetDetails = !isTrailer
    ? singlePlayDetails.assetDetails
    : trailerDetails;
  if (
    window &&
    (window as any).convivaAnalytic &&
    (window as any).Conviva &&
    !(window as any).parentalPinPopUpEnabled
  ) {
    // assetDetails.videoStartPoint = played_duration != 0? convertSecsToTime(played_duration) : "00:00:00"
    convivaAnalyticsTracker.updateConvivaIntitialContent(
      { ...assetDetails, ...keyOsDetails, ...tobaccoAdvisoryDetails },
      showDetails,
      userData,
      videoPlayer
    );
  }
}

export function convivaSessionStoppedOrWait(videoPlayer: any) {
  // Always Report Conviva ReportPlaybackend for previous content.
  if (
    window &&
    (window as any).convivaAnalytic &&
    (window as any).Conviva &&
    !(window as any).parentalPinPopUpEnabled
  ) {
    (window as any).convivaAnalytic.reportEvent(
      (window as any).Conviva.Constants.PlayerState.STOPPED
    );
  } else if ((window as any).parentalPinPopUpEnabled) {
    convivaAnalyticsTracker.convivaSessionWaitEnded(videoPlayer);
  }
}

export function resumeCovivaSession(videoPlayer: any) {
  // Resume Convvia Session for parental pin popup Enabled
  if ((window as any).parentalPinPopUpEnabled) {
    convivaAnalyticsTracker.convivaSessionWaitEnded(videoPlayer);
  }
}

function watchDurationCaluculator() {
  // const playDuration: any;
  const currentDate: any = new Date();
  const playDuration: number =
    (window as any).Math.abs(currentDate - playerTimestamp) / 1000;
  analyticsManager.reportEvent(
    playerEventsConstants.playbackEnded,
    playDuration
  );
}

export function onPlayEndedEventHelper(videoPlayer: any) {
  const {
    singlePlayDetails = {},
    playableData = {},
    playerConfig = {}
  }: any = playerRef || {};
  const { tvodState = null, toggleWatchTrailerButton = null } =
    playerConfig || {};
  const { entitlement: { isTVOD = 0 } = {} } = singlePlayDetails || {};
  const { asset_subtype = '' } = playableData || {};
  // to destroy up next carousel
  if (upNextCarouselState) {
    destroyUpNextCarousel();
  }
  if (
    tvodState &&
    (!tvodState.isUserEntitledForTvod || isTVOD) &&
    (asset_subtype === 'trailer' || asset_subtype === 'promo') &&
    tvodState.isTvodContent
  ) {
    playerConfig.triggerPopUp({ name: 'svodOrTvod' });
    toggleWatchTrailerButton({ disable: false });
    return null;
    // eslint-disable-next-line no-else-return
  } else if (!isTVOD) {
    // will not execute postplayback() if content is TVOD and it stops autoplay
    postPlaybackHandler(videoPlayer);
  }
  firstPlayHappened = false;
  isContentResumed = true;
  watchDurationCaluculator();
  convivaAnalyticsTracker.reportPlaybackEndedConviva();

  return null;
}

export function onVolumeChange(videoPlayer: any) {
  let oldVolumeLevel = getLocalStorage('oldVolumeLevel');
  const newVolumeLevel = videoPlayer.volume() || '1';
  if (
    oldVolumeLevel &&
    newVolumeLevel.toString() !== '1' &&
    oldVolumeLevel.toString() !== newVolumeLevel.toString()
  ) {
    setLocalStorage('oldVolumeLevel', newVolumeLevel.toString());
  } else {
    oldVolumeLevel = oldVolumeLevel || '1';
    setLocalStorage('oldVolumeLevel', oldVolumeLevel);
  }
}

export function onmuteChange(videoPlayer: any) {
  const newMutedStatus = (videoPlayer && videoPlayer.muted()) || false;
  setLocalStorage('oldMutedStatus', newMutedStatus.toString());
}

export function playerContainerFocused() {
  const element = document.querySelector<HTMLInputElement>('#video-tag');
  element?.focus();
}
export function fullScreenEvent(videoPlayer: any) {
  try {
    if (!isFullScreenFlag) {
      isFullScreenFlag = true;
      if (
        (document.getElementsByTagName('video')[0] as any)
          ?.webkitEnterFullScreen
      ) {
        (
          document.getElementsByTagName('video')[0] as any
        )?.webkitEnterFullScreen();
      }
      if (videoPlayer?.requestFullscreen) {
        videoPlayer.requestFullscreen();
      }
      playerContainerFocused();
    } else {
      isFullScreenFlag = false;
      if (
        (document.getElementsByTagName('video')[0] as any)?.webkitExitFullScreen
      ) {
        (
          document.getElementsByTagName('video')[0] as any
        )?.webkitExitFullScreen();
      }
      // TODO check Permission policy why full screen is not supported
      if (videoPlayer?.exitFullScreen) {
        videoPlayer?.exitFullScreen();
      }
      playerContainerFocused();
    }
    analyticsManager.reportEvent(
      playerEventsConstants.PLAYER_VIEW_CHANGED,
      null
    );
  } catch {
    console.log('error in full screen mode');
  }
}

export function subtitleChange(event: any) {
  // convivaAnalyticsTracker.updateConvivaInfo(subtitle)
  analyticsManager.reportEvent(event, null);
}

export function audioChange(
  audioChangeLang: string,
  isSportsLive: boolean,
  defaultStreamLanguage?: string
) {
  const { playerConfig = {} } = playerRef || {};
  const { updateAudioStream = null } = playerConfig || {};
  const eventPayload = {
    newAudioLanguage: getLanguageName(audioChangeLang),
    oldAudioLanguage: 'N/A'
  };
  if (isSportsLive && defaultStreamLanguage) {
    eventPayload.oldAudioLanguage =
      getLanguageName(defaultStreamLanguage) || 'N/A';
  }
  analyticsManager.reportEvent(
    playerEventsConstants.audioLanguageChange,
    eventPayload
  );
  convivaAnalyticsTracker.updateConvivaInfo({
    playbackType: 'Language Change'
  });
  if (isSportsLive) {
    setLocalStorage('prefferedAudioStream', audioChangeLang);
    setLocalStorage('languageChangeTriggered', 'true');
    updateAudioStream(audioChangeLang);
  }
}

export function videoQualityChange(selectedQuality: any) {
  const matchedMixpanelValue = getSelectedVideoQualityMixpanel(selectedQuality);
  const eventPayload = {
    newVideoQuality: matchedMixpanelValue || selectedQuality
  };
  analyticsManager.reportEvent(
    playerEventsConstants.qualityChange,
    eventPayload
  );
}

export function playerProgress() {
  convivaAnalyticsTracker.playerProgress();
}

export function convivaSessionKeyMomentsOnReady(videoPlayer: any) {
  const {
    singlePlayDetails = {},
    playerConfig = {},
    playableData = {},
    mixpanelData = {}
  } = playerRef || {};
  const {
    userData = {},
    userData: { user = {} } = {},
    userType = 'guest'
  } = playerConfig || {};
  const {
    error_code = '',
    entitlement: {
      is_dai = false,
      isLive = false,
      isTVOD = 0,
      isTrailer = 0
    } = {},
    assetDetails: { business_type = '' } = {}
  } = singlePlayDetails || {};
  // analyticsManager.jsonInitilize(null, null);
  // analyticsManager.setMixpanelData({});
  // window.analyticsManager = analyticsManager;
  // to check isdai or isLive
  (window as any).is_dai = is_dai;
  (window as any).isLive = isLive;
  const mandatoryRegistrationVideoCount =
    getLocalStorageInteger('mandatoryRegistrationVideoCount') || 0;
  (window as any).appVersion = mixpanelData.appVersion || 'NA';
  if (window && (window as any).drmSessionError) {
    convivaAnalyticsTracker.convivaDrmSessionWaitStarted(videoPlayer);
    (window as any).drmSessionError = false;
  } else if (
    !error_code &&
    (isTrailer ||
      isTVOD ||
      userType === 'premium' ||
      business_type === 'advertisement' ||
      business_type === 'free_downloadable' ||
      (mandatoryRegistrationVideoCount !== 3 &&
        mandatoryRegistrationVideoCount <= 7))
  ) {
    try {
      (window as any).convivaSDK.setPlayer(videoPlayer);
      console.log('OnReady, VideoPlayer Object integrated to ConvivaSDK');
    } catch (error) {
      console.error(
        'OnReady, Error while adding video Player to conviva SDK',
        error
      );
    }
    // convivaSessionStoppedOrWait(videoPlayer);
    (window as any).isConvivaSessionRequired = false;
    setConvivaInitialConentKeyMoments(singlePlayDetails, userData, videoPlayer);

    // reset mixpanel data to avoid conflicts with previous content
    analyticsManager.jsonInitilize(null, null);
    analyticsManager.setMixpanelData({});
    (window as any).analyticsManager = analyticsManager;
    // Mixpanel Opt in started
    mixpanelData.convivaSessionId = getConvivaSessionId();
    // SetUp initial Mixpanel Data.
    const configJson = {
      analyticData: playableData,
      singlePlayData: singlePlayDetails,
      mixpanelDetail: mixpanelData,
      user: user
    };
    const mixpanelJsonData = analyticsManager.jsonInitilize(
      configJson,
      videoPlayer
    );
    analyticsManager.setMixpanelData(mixpanelJsonData);
  }
}

export function convivaSessionOnReady(videoPlayer: any) {
  const {
    singlePlayDetails = {},
    playerConfig = {},
    playableData = {},
    mixpanelData = {}
  } = playerRef || {};
  const {
    userData = {},
    userData: { user = {} } = {},
    userType = 'guest'
  } = playerConfig || {};
  const {
    error_code = '',
    entitlement: {
      is_dai = false,
      isLive = false,
      isTVOD = 0,
      isTrailer = 0
    } = {},
    assetDetails: { business_type = '' } = {}
  } = singlePlayDetails || {};
  const { toggleSubscriptionPopup = null } = playerConfig || {};
  (window as any).toggleSubscriptionPopup = toggleSubscriptionPopup;
  // to check isdai or isLive
  (window as any).is_dai = is_dai;
  (window as any).isLive = isLive;
  const mandatoryRegistrationVideoCount =
    getLocalStorageInteger('mandatoryRegistrationVideoCount') || 0;
  (window as any).appVersion = mixpanelData.appVersion || 'NA';
  if (window && (window as any).drmSessionError) {
    convivaAnalyticsTracker.convivaDrmSessionWaitStarted(videoPlayer);
    (window as any).drmSessionError = false;
  } else if (
    !error_code &&
    (isTrailer ||
      isTVOD ||
      userType === 'premium' ||
      business_type === 'advertisement' ||
      business_type === 'free_downloadable' ||
      business_type === 'free' ||
      business_type === 'advertisement_downloadable' ||
      (mandatoryRegistrationVideoCount !== 3 &&
        mandatoryRegistrationVideoCount <= 7))
  ) {
    try {
      (window as any).convivaSDK.setPlayer(videoPlayer);
      console.log('OnReady, VideoPlayer Object integrated to ConvivaSDK');
    } catch (error) {
      console.error(
        'OnReady, Error while adding video Player to conviva SDK',
        error
      );
    }
    convivaSessionStoppedOrWait(videoPlayer);
    (window as any).isConvivaSessionRequired = false;
    setConvivaInitialConent(singlePlayDetails, userData, videoPlayer);
    let played_duration = 0;
    if (singlePlayDetails && singlePlayDetails.watchHistoryDetails) {
      const { watchHistoryDetails = {} } = singlePlayDetails || {};
      played_duration = watchHistoryDetails.played_duration
        ? watchHistoryDetails.played_duration
        : 0;
    }
    if (played_duration !== 0) {
      const videoStartPoint = {
        videoCueStart: convertSecsToTime(played_duration)
      };
      convivaAnalyticsTracker.updateConvivaInfo(videoStartPoint);
    }
    // reset mixpanel data to avoid conflicts with previous content
    analyticsManager.jsonInitilize(null, null);
    analyticsManager.setMixpanelData({});
    (window as any).analyticsManager = analyticsManager;
    // Mixpanel Opt in started
    mixpanelData.convivaSessionId = getConvivaSessionId();
    // SetUp initial Mixpanel Data.
    const configJson = {
      analyticData: playableData,
      singlePlayData: singlePlayDetails,
      mixpanelDetail: mixpanelData,
      user: user
    };
    const mixpanelJsonData = analyticsManager.jsonInitilize(
      configJson,
      videoPlayer
    );
    analyticsManager.setMixpanelData(mixpanelJsonData);
  }
}

export function onPauseEventHelper() {
  analyticsManager.reportEvent(playerEventsConstants.pauseEvent, null);
}

export function onSeekSrubEventHelper(seekDetails: any) {
  if (window && (window as any).autoSeek) {
    analyticsManager.reportEvent(playerEventsConstants.autoSeek, seekDetails);
    (window as any).autoSeek = false;
  } else {
    if (!(window as any)['isCastConnected']) {
      analyticsManager.reportEvent(
        playerEventsConstants.seek_scrub,
        seekDetails
      );
    }
  }
}

async function postPlaybackHandler(videoPlayer: any) {
  const {
    playerConfig = {},
    singlePlayDetails = {},
    similarFromReco = []
  } = playerRef || {};
  const {
    tvodObj = null,
    userType = 'guest',
    endTime = null,
    toggleWatchTrailerButton = null,
    startTime = null,
    primaryMediaId = '',
    getSinglePlayDetails = null,
    goLiveCallback = null,
    toggleSubscriptionPopup = null
  } = playerConfig || {};
  const {
    assetDetails = {},
    showDetails = {},
    entitlement: {
      isAVOD = 0,
      isTrailer = 0,
      isPremiumPlayback = 0,
      isAdAuthenticated = 0,
      isLive = false
    } = {}
  } = singlePlayDetails || {};
  const {
    asset_subtype = '',
    business_type = '',
    billing_type = ''
  } = assetDetails || {};
  const isLatest1Route =
    (window as any).location.pathname.indexOf('/latest1') >= 0;
  const isLatestRoute =
    !isLatest1Route &&
    (window as any).location.pathname.indexOf('/latest') >= 0;
  const isNewsCatchUp = endTime;
  const isBreakingNewsDvr = startTime && !endTime && !isLive;
  if (isNewsCatchUp || isBreakingNewsDvr) {
    goLiveCallback();
  } else if (isTrailer && isLatestRoute && !isAVOD) {
    if (
      (window as any).isRevisedSubscriptionJourney &&
      (window as any).isRevisedSubscriptionJourneyAutoPopup &&
      isCountryIndia() &&
      premiumBusinessType(business_type, billing_type) &&
      toggleSubscriptionPopup
    ) {
      toggleSubscriptionPopup();
    }
    playerConfig.triggerPopUp({ name: 'svodOrTvod' });
    invokeTeaserBanner();
    toggleWatchTrailerButton({ disable: false });
  } else if (
    (isTrailer && !assetDetails) ||
    (isAVOD && !isTrailer) ||
    isPremiumPlayback
  ) {
    if (
      primaryMediaId !== '' &&
      asset_subtype &&
      asset_subtype.indexOf('trailer') >= 0 &&
      (isAVOD || isPremiumPlayback)
    ) {
      if (tvodObj && tvodObj.showTvodOverlay) {
        tvodObj.showTvodOverlay();
      } else {
        getSinglePlayDetails({
          contentId: primaryMediaId,
          parentalPinStatus: true,
          parentalPinFlag: true
        });
      }
    } else if (
      userType !== 'premium' &&
      isAVOD &&
      (asset_subtype === 'trailer' ||
        asset_subtype === 'preview' ||
        asset_subtype === 'promo' ||
        (business_type === 'free_downloadable' &&
          showDetails.business_type === 'premium_downloadable'))
    ) {
      if (
        (window as any).isRevisedSubscriptionJourney &&
        (window as any).isRevisedSubscriptionJourneyAutoPopup &&
        isCountryIndia() &&
        premiumBusinessType(business_type, billing_type) &&
        toggleSubscriptionPopup
      ) {
        toggleSubscriptionPopup();
      }
      playerConfig.triggerPopUp({ name: 'svodOrTvod' });
    } else if (similarFromReco && similarFromReco[0] && similarFromReco[0].id) {
      playerConfig.requestToPlayNextVideo({
        contentid: similarFromReco[0].id,
        indexObject: undefined,
        playerRef,
        similarFromReco
      });
      sessionStorage.setItem('AutoPlay', 'Auto');
    }
  } else {
    if (
      document.fullscreenElement &&
      window.navigator.userAgent.match(/iPhone|iPad/i) &&
      isFullScreenFlag
    ) {
      document.exitFullscreen();
    }
    if (isAVOD && isTrailer && !isAdAuthenticated) {
      playerConfig.triggerPopUp({
        name: 'adultPopup',
        isAdvertisementAuthenticated: false
      });
    } else if (isAdAuthenticated && isTrailer) {
      playerConfig.triggerPopUp({
        name: 'adultPopup',
        isAdvertisementAuthenticated: true
      });
    } else {
      if (
        (window as any).isRevisedSubscriptionJourney &&
        (window as any).isRevisedSubscriptionJourneyAutoPopup &&
        isCountryIndia() &&
        premiumBusinessType(business_type, billing_type) &&
        toggleSubscriptionPopup
      ) {
        toggleSubscriptionPopup();
      }
      playerConfig.triggerPopUp({ name: 'svodOrTvod' });
      invokeTeaserBanner();
    }
  }
}

export async function getChromecastCustomData(videoPlayer: any) {
  const { playerConfig = {}, singlePlayDetails = {} } = playerRef || {};
  const { userType } = playerConfig || {};
  // const { usersData } =
  const contentType =
    singlePlayDetails.assetDetails &&
    singlePlayDetails.assetDetails.asset_subtype;
  // const trailerFallback = singlePlayDetails.entitlement && singlePlayDetails.entitlement.isTrailer;
  const contentId =
    singlePlayDetails.assetDetails && singlePlayDetails.assetDetails.id;
  const isTrailer =
    contentType === 'trailer' ||
    contentType === 'promo' ||
    contentType === 'preview';
  const isLatest1Route =
    !isTrailer && (window as any).location.pathname.indexOf('/latest1') >= 0;
  const isLatestRoute =
    !isTrailer &&
    !isLatest1Route &&
    (window as any).location.pathname.indexOf('/latest') >= 0;
  const params = {
    id: contentId,
    isChannel: (window as any).location.pathname.indexOf('/live-tv') >= 0,
    isLatestRoute,
    isLatest1Route,
    showId: '',
    isEpisodeConsumptionPage: false
  };
  if (params.id.split('-')[1] === '1') {
    const showId =
      singlePlayDetails.assetDetails && singlePlayDetails.assetDetails.tvshow;
    params['showId'] = showId;
    params['isEpisodeConsumptionPage'] = true;
  }
  const url = await playerConfig.singlePlayApiUrlGenerator(params);
  const xAccessToken = getCookieData('xaccesstoken');
  const userId = getUserId(userType);
  const authToken = `bearer ${getLocalStorage('token')}`;
  const customData = {
    spApiContentUrl: url,
    xAccessToken,
    age: 20,
    gender: 'Male',
    userType,
    userId,
    isAutoplay: false,
    isRecommended: false,
    activeSubtitle: getActiveSubtitles(videoPlayer),
    guestToken: '',
    authorization: authToken
  };
  return customData;
}

export function getPosterUrl() {
  const { playerConfig = {}, singlePlayDetails = {} } = playerRef || {};
  const content = singlePlayDetails.assetDetails;
  const posterUrl = playerConfig.getPosterUrl({
    tagName: 'playerPoster',
    item: content
  });
  return posterUrl;
}

export function triggerWatchHistory(videoPlayer: any, fromUpNext = false) {
  const {
    playableData = {},
    playerConfig = {},
    singlePlayDetails
  }: any = playerRef || {};
  const {
    guestToken,
    watchHistoryApi,
    guestConfigCWLS,
    guestConfigCW,
    continueWatchingItemForGuest
  } = playerConfig;

  let playerCurrentTime = 0;
  if (videoPlayer && 'currentTime' in videoPlayer) {
    playerCurrentTime = videoPlayer.currentTime();
  }

  const canSendWatchHistory = (playerCurrentTime: number) => {
    if (playableData.watchHistory) {
      return checkDurationtoSend(
        playerCurrentTime,
        playableData.watchHistory.lastSentTime
      );
    } else {
      playableData.watchHistory = { lastSentTime: playerCurrentTime || 0 };
      return true;
    }
  };
  const checkDurationtoSend = (
    playerCurrentTime: number,
    lastSentTime: number
  ) => {
    if (Math.abs(playerCurrentTime - lastSentTime) >= 60) {
      playableData.watchHistory.lastSentTime = playerCurrentTime;
      return true;
    }
    return false;
  };
  if (
    playerCurrentTime &&
    (canSendWatchHistory(playerCurrentTime) || fromUpNext) &&
    playableData
  ) {
    let watchMethod = 'PUT';
    if (playerCurrentTime === 0) {
      watchMethod = 'POST';
    }
    const bodyParams: UserWatch = {
      id: playableData.id ? playableData.id : '',
      asset_subtype: playableData.asset_subtype
        ? playableData.asset_subtype
        : '',
      asset_type:
        playableData.asset_type || playableData.asset_type === 0
          ? playableData.asset_type
          : '',
      genre: [
        playableData.genres && Object.keys(playableData.genres).length
          ? playableData.genres[0].id
          : ''
      ],
      duration: Math.round(playerCurrentTime) || 0,
      show_id: playableData.id ? playableData.id : '',
      title: playableData.title ? playableData.title : '',
      total_duration: playableData.duration ? playableData.duration : '',
      business_type: playableData.business_type
        ? playableData.business_type
        : '',
      device_id: guestToken || 'WebBrowser'
    };
    const USER_TYPE = 'userType';
    const GUEST_WATCH_HISTORY = 'WatchHistoryLS';
    const isGuestUser = getLocalStorage(USER_TYPE).toLowerCase() === 'guest';
    const isEpisode = playableData.asset_subtype === 'episode';
    if (guestConfigCW && guestConfigCWLS && isGuestUser) {
      (bodyParams.image = playableData.image),
        (bodyParams.image_url = playableData.image_url),
        (bodyParams.web_slug = playableData.web_slug),
        (bodyParams.slug = playableData.web_slug),
        (bodyParams.web_url = playableData.web_url);
      if (isEpisode) {
        bodyParams.tvshow = singlePlayDetails.showDetails;
        bodyParams.tvshow_details = singlePlayDetails.showDetails;
        bodyParams.tvshow_image = singlePlayDetails.showDetails.image;
      }
    }
    if (
      guestConfigCW &&
      guestConfigCWLS &&
      isGuestUser &&
      (playableData.asset_subtype === 'episode' ||
        playableData.asset_subtype === 'movie')
    ) {
      bodyParams.played_duration =
        Math.round(
          videoPlayer &&
            'currentTime' in videoPlayer &&
            videoPlayer.currentTime()
        ) || 0;
      bodyParams.duration = bodyParams.total_duration;
      bodyParams.time_left = getLeftTime(
        bodyParams.total_duration - bodyParams.played_duration
      );
      const watchHistoryLocalStorage = getLocalStorage(GUEST_WATCH_HISTORY);
      if (!watchHistoryLocalStorage) {
        const array: any[] = [];
        array.push(bodyParams);
        setLocalStorage(GUEST_WATCH_HISTORY, JSON.stringify(array));
      } else {
        const watchHistoryLocalStorage = getLocalStorage(GUEST_WATCH_HISTORY);
        const array = JSON.parse(watchHistoryLocalStorage);
        let ditem = {};
        array.forEach((item: any, index: number) => {
          if (
            isEpisode &&
            item.tvshow_details &&
            item.tvshow_details.id === singlePlayDetails.showDetails.id &&
            item.id !== bodyParams.id
          ) {
            array.splice(index, 1);
          }
          if (item.id.includes(bodyParams.id)) {
            if (bodyParams?.total_duration && bodyParams?.played_duration) {
              item.time_left = getLeftTime(
                bodyParams.total_duration - bodyParams.played_duration
              );
            }
            item.played_duration =
              Math.round(
                videoPlayer &&
                  'currentTime' in videoPlayer &&
                  videoPlayer.currentTime()
              ) || 0;
            ditem = item;
            array.splice(index, 1);
          }
        });
        if (ditem && Object.keys(ditem).length) {
          array.push(ditem);
        } else {
          array.push(bodyParams);
        }
        if (array.length > continueWatchingItemForGuest) {
          array.splice(0, 1);
        }
        setLocalStorage(GUEST_WATCH_HISTORY, JSON.stringify(array));
      }
    } else {
      watchHistoryApi({ watchMethod, bodyParams });
    }
  }
}

export function onErrorHelper(error: any, videoPlayer: any, playerConfig: any) {
  const { code: errorCode = 'N/A', message: errorMessage = 'N/A' } = error;
  const formattedErrorCode = getFormattedErrorCode(errorCode);
  if (
    errorCode.toString() === ErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED.toString()
  ) {
    if (document.getElementsByClassName('main-container').length > 0) {
      document.getElementsByClassName('main-container')[0].remove();
    }
    playerConfig.triggerPopUp({
      name: 'networkRetry',
      playerErrorCode: formattedErrorCode
    });
    convivaAnalyticsTracker.reportErrorEvent(
      'vsferror',
      `${formattedErrorCode}`
    );
    (
      document.querySelector<HTMLElement>('.vjs-loading-spinner') as HTMLElement
    ).style.display = 'none';
  } else if (
    errorCode.toString() === ErrorCode.TIMEOUT.toString() ||
    errorCode.toString() === ErrorCode.UNSUPPORTED_SCHEME.toString() ||
    errorCode.toString() === ErrorCode.BAD_HTTP_STATUS.toString() ||
    errorCode.toString() === ErrorCode.HTTP_ERROR.toString() ||
    errorCode.toString() === ErrorCode.NETWORK.toString()
  ) {
    videoPlayer && videoPlayer.pause();
    playerConfig.triggerPopUp({
      name: 'networkRetry',
      playerErrorCode: formattedErrorCode
    });
    convivaAnalyticsTracker.reportErrorEvent(
      'vsferror',
      `${formattedErrorCode}`
    );
  }
  convivaAnalyticsTracker.updateConvivaInfo({
    infoMessage: JSON.stringify({
      errorCode: `${formattedErrorCode}`,
      errorMessage: `${errorMessage}`,
      error: JSON.stringify(error)
    })
  });
}

export function getFormattedErrorCode(player_error_code: any) {
  let selfErrors = getLocalStorage('selfErrors') || {};
  if (selfErrors && typeof selfErrors === 'string') {
    selfErrors = JSON.parse(selfErrors);
  }
  let errorMapping = getLocalStorage('errorMapping') || mapZeePlayerCodes;
  if (errorMapping && typeof errorMapping === 'string') {
    errorMapping = JSON.parse(errorMapping);
  } else {
    errorMapping = mapZeePlayerCodes;
  }
  const getZeeCodeMap = errorMapping[player_error_code.toString()];
  const getZeeObject = selfErrors[getZeeCodeMap] || {};
  const zee_error_code =
    (getZeeObject &&
      getZeeObject.error_category_code &&
      getZeeObject.error_category_code.toString()) ||
    'NA';
  const errorDisplayCode = zee_error_code
    .concat('-', player_error_code.toString())
    .concat('-', '0');
  return errorDisplayCode;
}

// To add skip (play next) button when reco items update for popups
export function triggerRecoSkipButton() {
  const { playerConfig = {} }: any = playerRef || {};
  const { createSkipContentButton = null } = playerConfig || {};
  if (createSkipContentButton) {
    //to be checked
    createSkipContentButton({ playerRef });
  }
}

export function advertisementPlayerBanner() {
  invokeTeaserBanner();
}

export function tobaccoAdvisory(videoPlayer: any, playerCurrentTime: number) {
  const { singlePlayDetails = {}, playerConfig = {} }: any = playerRef || {};
  const { payload: tobaccoAdvisoryConfig } =
    playerConfig?.getFeatureFlagConfig(FeatureFlagConstant.CONFIG_TOBACCO) ||
    {};
  //for aston segments
  if (
    singlePlayDetails?.tobaccoAdvisoryDetails?.aston_segments &&
    tobaccoAdvisoryConfig?.aston_band_config?.enable
  ) {
    let astonSegments = singlePlayDetails.tobaccoAdvisoryDetails.aston_segments;
    const ifShowTobaccoAdvisory = checkToShowTobaccoAdvisory(
      astonSegments,
      playerCurrentTime
    );
    if (ifShowTobaccoAdvisory) {
      let audioTracks = videoPlayer.audioTracks();
      let audioLanguage = '';
      for (let i = 0; i < audioTracks.length; i++) {
        let track = audioTracks[i];
        if (track.enabled) {
          audioLanguage = track.language;
        }
      }
      let astonImgPath =
        tobaccoAdvisoryConfig?.aston_band_config?.image_path_url;
      if (
        audioLanguage &&
        singlePlayDetails?.tobaccoAdvisoryDetails?.images &&
        singlePlayDetails?.tobaccoAdvisoryDetails?.images[audioLanguage]
      ) {
        astonImgPath =
          astonImgPath +
          singlePlayDetails.tobaccoAdvisoryDetails.images[audioLanguage];
      } else if (singlePlayDetails?.tobaccoAdvisoryDetails?.images?.en) {
        astonImgPath =
          astonImgPath + singlePlayDetails.tobaccoAdvisoryDetails.images.en;
      } else if (
        singlePlayDetails?.assetDetails?.audio_languages &&
        singlePlayDetails?.assetDetails?.audio_languages.length > 0 &&
        singlePlayDetails?.tobaccoAdvisoryDetails?.images &&
        singlePlayDetails?.tobaccoAdvisoryDetails?.images[
          singlePlayDetails.assetDetails.audio_languages[0]
        ]
      ) {
        astonImgPath =
          astonImgPath +
          singlePlayDetails.tobaccoAdvisoryDetails.images[
            singlePlayDetails.assetDetails.audio_languages[0]
          ];
      }
      if (astonImgPath) {
        showTobaccoAdvisory(
          videoPlayer,
          astonImgPath,
          audioLanguage,
          analyticsManager
        );
      }
    } else {
      removeTobaccoAdvisory();
    }
  }
  //for advisory video
  if (
    singlePlayDetails?.tobaccoAdvisoryDetails?.video_segments &&
    tobaccoAdvisoryConfig?.video_segment_config?.enable
  ) {
    let videoSegments = singlePlayDetails.tobaccoAdvisoryDetails.video_segments;
    const midRollVideoSegments = getTobaccoAdvisoryVideoSegment(
      videoSegments,
      'midRoll',
      playerCurrentTime
    );
    if (
      midRollVideoSegments &&
      midRollVideoSegments.video_uri &&
      !midTobaccoAdvisoryVideoShown
    ) {
      midTobaccoAdvisoryVideoShown = true;
      let eventPayLoad = {
        healthSpotPlacement: 'Mid-roll',
        advisoryVideoUrl: midRollVideoSegments.video_uri
      };
      analyticsManager.reportEvent(
        playerEventsConstants.healthSpotViewed,
        eventPayLoad
      );
      playerConfig.triggerPopUp({
        name: 'tobaccoAdvisory',
        videoUrl: midRollVideoSegments.video_uri
      });
    }
  }
}

export function showTobaccoAdvisoryPreRollVideo() {
  let { playerConfig = {}, singlePlayDetails = {} }: any = playerRef || {};
  let preRollVideoSegments = getTobaccoAdvisoryVideoSegment(
    singlePlayDetails.tobaccoAdvisoryDetails.video_segments,
    'preRoll'
  );

  //for advisory video
  if (preRollVideoSegments && preRollVideoSegments.video_uri) {
    let eventPayLoad = {
      healthSpotPlacement: 'Pre-roll',
      advisoryVideoUrl: preRollVideoSegments.video_uri
    };
    analyticsManager.reportEvent(
      playerEventsConstants.healthSpotViewed,
      eventPayLoad
    );
    playerConfig.triggerPopUp({
      name: 'tobaccoAdvisory',
      videoUrl: preRollVideoSegments.video_uri
    });
  }
}

function checkToShowTobaccoAdvisory(
  astonSegments: any[],
  playerCurrentTime: number
) {
  return astonSegments.some(
    (segment) =>
      playerCurrentTime > segment.start_marker &&
      playerCurrentTime < segment.end_marker
  );
}

function getTobaccoAdvisoryVideoSegment(
  videoSegments: any[],
  type: string,
  playerCurrentTime?: number
) {
  if (type === 'preRoll') {
    return videoSegments.find((segment) => segment.start_marker === 0);
  } else if (type === 'midRoll' && playerCurrentTime) {
    return videoSegments.find(
      (segment) =>
        playerCurrentTime > segment.start_marker &&
        playerCurrentTime < segment.start_marker + 2 &&
        segment.start_marker !== 0
    );
  }
}

export function onTimeUpdateEventHelper(videoPlayer: any) {
  const {
    singlePlayDetails = {},
    playerConfig = {},
    playableData = {},
    similarFromReco = []
  }: any = playerRef || {};
  const { entitlement = {} } = singlePlayDetails || {};
  const { isAVOD, isTrailer, isAdAuthenticated } = entitlement || {};
  const {
    skip_available: { intro_start_s = '', intro_end_s = '' } = {},
    end_credits_start_s = '',
    asset_subtype = ''
  } = playableData || {};
  const {
    contentType = '',
    toggleWatchTrailerButton = null,
    getUpnextData = null
  } = playerConfig;
  const skipIntroStartTime = convertToSeconds(intro_start_s);
  const skipIntroEndTime = convertToSeconds(intro_end_s);
  const playerCurrentTime = videoPlayer.currentTime();
  tobaccoAdvisory(videoPlayer, playerCurrentTime);
  const skipIntroBtn = document.getElementsByClassName('skip_intro_button')[0];
  const watchCreditsBtn = document.getElementsByClassName(
    'watch_credits_button'
  )[0];
  const duration = Math.round(videoPlayer.duration()) || 0;
  const { messages = {} } = (window as any).translationsAndConfigs || {};
  convivaAnalyticsTracker.updateBufferPlayHeadTime(
    playerEventsConstants.updateBufferPlayHeadTime
  );
  const timeDurationVideo = {
    contentLengthSec:
      playerCurrentTime != 0
        ? (playerCurrentTime * 1000).toFixed(0).toString()
        : playerEventsConstants.Not_Applicable
  };
  convivaAnalyticsTracker.updateConvivaInfo(timeDurationVideo);
  // Skip intro handling
  if (
    ((playerCurrentTime &&
      playerCurrentTime >= skipIntroStartTime &&
      playerCurrentTime < skipIntroEndTime) ||
      (!playerCurrentTime &&
        watchTime &&
        watchTime >= skipIntroStartTime &&
        watchTime < skipIntroEndTime)) &&
    !skipIntroBtn &&
    asset_subtype !== 'trailer'
  ) {
    addSkipIntroButton(
      videoPlayer,
      skipIntroEndTime,
      skipIntroStartTime,
      analyticsManager
    );
  } else if (
    (playerCurrentTime < skipIntroStartTime ||
      playerCurrentTime >= skipIntroEndTime) &&
    skipIntroBtn
  ) {
    document.getElementsByClassName('video-js')[0].removeChild(skipIntroBtn);
  }

  let shopIcon = document.querySelector('.initialShopIconContainer');
  if (Math.round(playerCurrentTime) >= 0 && shopIcon) {
    document.getElementsByClassName('video-js')[0].removeChild(shopIcon);
  }
  detectChromeCastForShopIcon();
  if (oneTimeFnRenderInCurrentTime) {
    commerceFeatureFlagInCurrentTime = checkOfferJsonDataForCommerceFlag();
    oneTimeFnRenderInCurrentTime = false;
  }

  if (commerceFeatureFlagInCurrentTime) {
    playerConfig.currentTime(playerCurrentTime);
  }
  // end skip intro

  if (
    upNextCarouselState &&
    isNotTvodContent(playerRef) &&
    counterStartTime > 0
  ) {
    const list = document.getElementsByClassName('upNextStart');
    if (list) {
      for (let i = 0; i < list.length; i++) {
        (list[i] as HTMLElement).style.display = 'none';
      }

      const upNextStart = document.createElement('div');
      if (upNextStart) {
        upNextStart.className = 'upNextStart';
        // need to check below condition
        if (end_credits_start_s) {
          upNextStart.innerHTML =
            `${
              'Starts in ' + '<span class="second-color">'
            }${counterStartTime} s` + `</span>`;
          counterStartTime = continousplayTime
            ? Math.floor(continousplayTime) -
              Math.floor(videoPlayer.currentTime())
            : counterStartTime;
          if (!continousplayTime) {
            continousplayTime = Math.floor(videoPlayer.currentTime()) + 5;
          }
          if (
            Math.floor(videoPlayer.currentTime()) >= continousplayTime &&
            Math.floor(videoPlayer.currentTime()) <
              Math.floor(videoPlayer.duration())
          ) {
            continousplayTime = null;
            postPlaybackHandler(videoPlayer);
          }
        } else {
          upNextStart.innerHTML =
            `${'Starts in ' + '<span class="second-color">'}${
              Math.round(videoPlayer.duration()) -
              Math.round(videoPlayer.currentTime())
            } s` + `</span>`;
        }
        const update = document.getElementsByClassName('metadataContainer');
        if (update && update[0]) {
          const contentId = sessionStorage.getItem('contentId');
          upNextStart.id = contentId || '';
          update[0].appendChild(upNextStart);
          upNextStart.style.display = 'block';
        }
      }
    }
  }

  if (
    contentType &&
    contentType !== 'livetv' &&
    (!(isAVOD && isTrailer) || (isAdAuthenticated && isTrailer))
  ) {
    if (watchCreditsTime > 0) {
      watchCreditsStartTime = duration - watchCreditsTime;
    } else {
      // Showing carousel fallback
      if (Object.keys(messages).length && !watchCreditsTime) {
        watchCreditsStartTime = duration - messages.duration;
      } else {
        watchCreditsStartTime = duration - 5;
      }
    }

    if (watchCreditsStartTime > 0) {
      upNextApiTime = watchCreditsStartTime - 60;
    }
    if (
      upNextApiTime !== 0 &&
      playerCurrentTime >= upNextApiTime &&
      !upNextApiState &&
      !isUpNextPending &&
      !isPopupVideo
    ) {
      getUpnextData();
      isUpNextPending = true;
    }
    // Watch credits and carousel construction
    if (
      !upNextCarouselState &&
      playerCurrentTime >= watchCreditsStartTime &&
      asset_subtype !== 'trailer' &&
      asset_subtype !== 'preview' &&
      watchCreditsStartTime !== 0 &&
      !watchCreditsBtn &&
      upNextApiState &&
      b2bUpnextFlag
    ) {
      if (
        similarFromReco &&
        similarFromReco.length &&
        isNotTvodContent(playerConfig) &&
        playerCurrentTime !== 0
      ) {
        upNextCarousel(videoPlayer);
        if (watchCreditsTime > 0) {
          watchCredits(
            videoPlayer,
            upNextCarouselState,
            toggleWatchTrailerButton,
            analyticsManager
          );
        }
      }
      // if (watchCreditsTime > 0 && isNotTvodContent(playerConfig) && !watchCreditsConstructedFlag) {
      //   watchCredits(
      //     videoPlayer,
      //     upNextCarouselState,
      //     toggleWatchTrailerButton,
      //     analyticsManager
      //   );
      //   watchCreditsConstructedFlag = true;
      // }
    } else if (
      playerCurrentTime < watchCreditsStartTime &&
      upNextCarouselState
    ) {
      destroyUpNextCarousel();
      upNextCarouselState = false;
      toggleWatchTrailerButton({ disable: false });
    }
  }
}

// Up Next carousel scroll functions
function upNextCarouselScroll() {
  startScrollTime = new Date();
  if (!startScroll) {
    startScroll = (document.querySelector('.up-next-carousel') as HTMLElement)
      .scrollLeft;
  }
  if (scrollTimeout === false) {
    scrollTimeout = true;
    setTimeout(() => scrollDebounceFn(), 200);
  }
}

function scrollDebounceFn() {
  if (startScrollTime - startScrollTime < 200) {
    setTimeout(() => scrollDebounceFn(), 200);
  } else {
    scrollTimeout = false;
    let scrollDirection;
    if (
      startScroll <
      (document.querySelector('.up-next-carousel') as HTMLElement).scrollLeft
    ) {
      scrollDirection = 'Right';
    } else {
      scrollDirection = 'Left';
    }
    startScroll = 0;
    console.log('scroll direction', scrollDirection);
  }
}
// end

// Up Next carousel function
export function upNextCarousel(videoPlayer: any) {
  const { similarFromReco = [], playerConfig = {} }: any = playerRef || {};
  const {
    modelName = '',
    requestToPlayNextVideo = null,
    toggleWatchTrailerButton = null
  } = playerConfig;
  const carouselData: any[] = [];
  toggleWatchTrailerButton({ disable: true });
  upNextCarouselState = true;
  for (let i = 0; i < similarFromReco.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    const imageUrlKey = Object.prototype.hasOwnProperty.call(
      similarFromReco[i],
      'imageUrl'
    )
      ? 'imageUrl'
      : 'image_url';
    carouselData.push({
      imageUrl:
        typeof similarFromReco[i][imageUrlKey] === 'object'
          ? similarFromReco[i][imageUrlKey].list
          : similarFromReco[i][imageUrlKey],
      content_id: similarFromReco[i].id,
      title: similarFromReco[i].title,
      origin: similarFromReco[i].origin,
      clickID: similarFromReco[i].clickID
    });
  }

  const carouselContainer = document.createElement('div');
  carouselContainer.className = 'up-next-carousel';
  carouselContainer.id = 'carouselSliderContainer';

  for (let j = 0; j < carouselData.length; j++) {
    const carouselSlide = document.createElement('div');
    carouselSlide.setAttribute('id', carouselData[j].content_id);
    carouselSlide.setAttribute('data-contentId', carouselData[j].content_id);
    carouselSlide.className = 'carousel-item-slide';
    if (carouselData[j].asset_subtype == 'episode') {
      carouselSlide.setAttribute(
        'data-episodeId',
        carouselData[j].episode_number
      );
      carouselSlide.setAttribute('data-seriesId', carouselData[j].season.id);
    }

    const mainCarouselContainer = document.createElement('div');
    mainCarouselContainer.className = 'mainCarouselDiv';
    const carousalImage = document.createElement('img');
    carousalImage.src = carouselData[j].imageUrl;
    carousalImage.id = carouselData[j].content_id;
    carousalImage.className = 'carousal-video';
    carousalImage.crossOrigin = 'anonymous';
    mainCarouselContainer.appendChild(carousalImage);
    sessionStorage.setItem('contentId', carouselData[j].content_id);

    const carouselMetatadata = document.createElement('div');
    carouselMetatadata.className = 'metadataContainer';
    carouselMetatadata.id = carouselData[j].content_id;

    if (j === 0) {
      const upNext = document.createElement('div');
      upNext.className = 'upNext';
      upNext.innerHTML = 'UP NEXT';
      upNext.id = carouselData[j].content_id;
      carouselMetatadata.appendChild(upNext);
    }
    const newTitle = carouselData[j].title.split('-');
    const upNextTitle = document.createElement('div');
    upNextTitle.className = 'upNextTitle';
    upNextTitle.innerHTML = newTitle[0];
    upNextTitle.id = carouselData[j].content_id;
    carouselMetatadata.appendChild(upNextTitle);

    // todo - should add upNextStartInTime logic
    if (j === 0 && Math.round(videoPlayer.currentTime()) >= upNextStartInTime) {
      const upNextStart = document.createElement('div');
      upNextStart.className = 'upNextStart';
      upNextStart.innerHTML =
        `${'Starts in ' + '<span class="second-color">'}${
          Math.round(videoPlayer.duration()) -
          Math.round(videoPlayer.currentTime())
        } s` + `</span>`;
      upNextStart.id = carouselData[j].content_id;
      carouselMetatadata.appendChild(upNextStart);
    }
    if (!mainCarouselContainer.id || mainCarouselContainer.id === '') {
      mainCarouselContainer.id = carouselData[j].content_id;
    }
    mainCarouselContainer.appendChild(carouselMetatadata);
    carouselSlide.appendChild(mainCarouselContainer);
    carouselContainer.appendChild(carouselSlide);

    // play clicked video..........
    carouselSlide.addEventListener('click', (e) => {
      // iscarousalClick = true;
      const content_id = (e.target as HTMLInputElement).id;
      if (videoPlayer) {
        videoPlayer.pause();
        destroyUpNextCarousel();
        b2bUpnextFlag = false;
      }
      let indexObject = {};
      for (let i = 0; i < carouselData.length; i++) {
        if (content_id === carouselData[i].content_id) {
          indexObject = {
            assetSubtype: carouselData[i].origin ? 'Reco' : undefined,
            talamoosModelName: modelName,
            origin: carouselData[i].origin,
            clickID: carouselData[i].clickID,
            horizontalIndex: i + 1
          };
          break;
        }
      }

      if (requestToPlayNextVideo) {
        requestToPlayNextVideo({
          contentid: content_id,
          indexObject,
          playerRef,
          similarFromReco
        });
        sessionStorage.setItem('AutoPlay', 'Manual');
      }
      const watchCreditsContainer = document.getElementById('watchCreditsId');
      if (watchCreditsContainer && watchCreditsContainer !== null) {
        watchCreditsContainer.remove();
      }
      upNextCarouselState = false;
    });
  }

  // next and prev btn.....
  const carousalButtonsContainer = document.createElement('div');
  if (carouselData.length > 2) {
    carousalButtonsContainer.setAttribute('class', 'carousal-button-container');

    const carouselPrevIcon = document.createElement('img');
    carouselPrevIcon.setAttribute('class', 'carousal-prev-button');
    carouselPrevIcon.setAttribute('id', 'prevSlide');
    carouselPrevIcon.setAttribute(
      'src',
      'https://stcf-prod.zee5.com/prod/zee5Videojs/resources/images/carousal-prev.png'
    );
    carouselPrevIcon.style.display = 'none';
    const carouselNextIcon = document.createElement('img');
    carouselNextIcon.setAttribute('class', 'carousal-next-button');
    carouselNextIcon.setAttribute('id', 'nextSlide');
    carouselNextIcon.setAttribute(
      'src',
      'https://stcf-prod.zee5.com/prod/zee5Videojs/resources/images/carousal-next.png'
    );

    carousalButtonsContainer.appendChild(carouselPrevIcon);
    carousalButtonsContainer.appendChild(carouselNextIcon);

    carouselNextIcon.addEventListener('click', () => {
      const prevSlide = document.querySelector('#prevSlide') as HTMLElement;
      if (!isInViewport(prevSlide)) {
        (document.getElementById('prevSlide') as HTMLElement).style.display =
          'block';
      }
      const carousalList = document.getElementById(
        'carouselSliderContainer'
      ) as HTMLElement;
      carousalList.scrollLeft += 280;
      const width = carousalList.offsetWidth + carousalList.scrollLeft;
      if (carousalList.scrollWidth == width) {
        (document.getElementById('nextSlide') as HTMLElement).style.display =
          'none';
      }
    });

    carouselPrevIcon.addEventListener('click', () => {
      const nextSlide = document.querySelector('#nextSlide') as HTMLElement;
      if (!isInViewport(nextSlide)) {
        (document.getElementById('nextSlide') as HTMLElement).style.display =
          'block';
      }
      const carousalList = document.getElementById(
        'carouselSliderContainer'
      ) as HTMLElement;
      carousalList.scrollLeft -= 280;
      if (carousalList.scrollLeft == 0) {
        (document.getElementById('prevSlide') as HTMLElement).style.display =
          'none';
        carouselNextIcon.style.marginTop = '0px';
      }
    });
  }
  let videoContainer;
  if (
    document.querySelector('.playerContainer')?.children?.[0]?.tagName === 'DIV'
  ) {
    videoContainer = document.querySelector('#video-tag') as HTMLElement;
  } else {
    videoContainer = document.querySelector('.playerContainer') as HTMLElement;
  }
  videoContainer.appendChild(carouselContainer);
  videoContainer.appendChild(carousalButtonsContainer);

  const isInViewport = function (elem: HTMLElement) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top > 0 &&
      bounding.left > 0 &&
      bounding.bottom <=
        ((window as any).innerHeight ||
          document.documentElement.clientHeight) &&
      bounding.right <=
        ((window as any).innerWidth || document.documentElement.clientWidth)
    );
  };
  const isMobile = /iPhone|iPad|iPod|Android/i.test(
    (window as any).navigator.userAgent
  );
  if (isMobile) {
    const carousalList = document.getElementById(
      'carouselSliderContainer'
    ) as HTMLElement;
    carousalList.addEventListener('scroll', upNextCarouselScroll);
  }
  const playerControls = document.querySelector('.vjs-skip-wrapper');
  if (playerControls && playerControls !== null) {
    playerControls.classList.add('upNextCarouselActive');
  }
}

export function destroyUpNextCarousel(
  continueCredits?: string,
  videoPlayer?: any
) {
  counterStartTime = 5;
  let videoContainer;
  if (
    document.querySelector('.playerContainer')?.children?.[0]?.tagName === 'DIV'
  ) {
    videoContainer = document.querySelector('#video-tag') as HTMLElement;
  } else {
    videoContainer = document.querySelector('.playerContainer') as HTMLElement;
  }

  if (continueCredits === 'continueCredits') {
    continousplayTime = Math.floor(videoPlayer.duration());
  } else {
    continousplayTime = null;
  }
  if (document.getElementsByClassName('up-next-carousel').length) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(
      (window as any).navigator.userAgent
    );
    if (isMobile) {
      const carousalList = document.getElementById(
        'carouselSliderContainer'
      ) as HTMLElement;
      carousalList.removeEventListener('scroll', upNextCarouselScroll);
    }
    const carousalSlider = document.querySelector(
      '.up-next-carousel'
    ) as HTMLElement;
    videoContainer.removeChild(carousalSlider);
    const carousalButtons = document.querySelector(
      '.carousal-button-container'
    );
    if (carousalButtons) {
      videoContainer.removeChild(carousalButtons);
    }
  }

  // todo - removing watch credit cta after destroying carosuel
  const watchCreditContainer = document.querySelector('.video-js');
  const watchCreditText = document.querySelector('.watch_credits_button');
  if (watchCreditContainer && watchCreditText) {
    watchCreditContainer.removeChild(watchCreditText);
  }
}

export function triggerErrorMappingUrl(playerConfig: any) {
  const { selfErrorComponent = null } = playerConfig || {};
  if (selfErrorComponent) {
    selfErrorComponent();
  }
}

export function subscriptionNudgeOnPlayerPopup(
  videoPlayer: any,
  playerConfig: any
) {
  if (checkForSpecialPlan()) {
    playerConfig.triggerPopUp({ name: 'subscriptionNudge' });
  } else {
    const interval = setInterval(() => {
      if (checkForSpecialPlan()) {
        clearInterval(interval);
        playerConfig.triggerPopUp({ name: 'subscriptionNudge' });
      }
    }, 500);
  }
}

export function removeSubscriptionNudgeOnPlayer(
  videoPlayer: any,
  playerConfig: any
) {
  const { removeSubscriptionNudge = null } = playerConfig || {};
  if (removeSubscriptionNudge) {
    removeSubscriptionNudge();
  }
}

export function triggerNetworkRetry(playerConfig: any) {
  const { networkRetry } = playerConfig || {};
  networkRetry();
}

export function showIsTrailer(val: any) {
  const trailerInfoContainer = document.querySelector<HTMLElement>(
    '.trailerInfoContainer'
  );
  if (trailerInfoContainer) {
    if (val) {
      trailerInfoContainer.style.display = 'block';
    } else {
      trailerInfoContainer.style.display = 'none';
    }
  }
}

export function getActiveSubtitles(videoPlayer: any) {
  let activeSubtitle = null;
  videoPlayer.textTracks().tracks_.forEach((item: any) => {
    if (item.mode === 'showing') {
      activeSubtitle = item.language;
      return activeSubtitle;
    }
  });
  return activeSubtitle;
}

export function handleStaleApiCall(videoPlayer: any) {
  const {
    singlePlayDetails = {},
    playableData = {},
    playerConfig = {}
  }: any = playerRef || {};
  if (
    !isStaleApisCalled &&
    videoPlayer &&
    playerConfig &&
    playerConfig.callStaledApis
  ) {
    const timeDiff = Math.floor(
      ((new Date() as any) - singlePlayDetails.timeStamp) / 1000
    );
    let currentTime = 0;
    if (videoPlayer.currentTime) {
      currentTime =
        Math.abs(
          videoPlayer.currentTime() - playableData?.watchHistory?.lastSentTime
        ) || 0;
    }
    const staleApiTimestampCutoffSecs = isMobileDevice() ? 3 : 1;
    const isSufficientTimeLapsed =
      currentTime >= staleApiTimestampCutoffSecs || timeDiff >= 15; // TODO make the timings configurable and ability to change
    if (isSufficientTimeLapsed) {
      playerConfig.callStaledApis();
      isStaleApisCalled = true;
    }
  }
}

// export function SOShandler(
//   videoPlayer: any,
//   sosAssetType: any,
//   sosAssetSubtype: any,
//   sosCapping: any
// ) {
//   const qualityLevels = videoPlayer.qualityLevels();
//   if (
//     isValidSOSAssetType(sosAssetType, sosAssetSubtype) &&
//     qualityLevels &&
//     qualityLevels.length > 0
//   ) {
//     if (isSOSCappingValid(sosCapping)) {
//       if (sosCapping !== prevCapping) {
//         for (let i = 0; i < qualityLevels.length; i++) {
//           if (qualityLevels[i].height <= sosCapping) {
//             qualityLevels[i].enabled = true;
//             qualityLevels.selectedIndex_ = i;
//           } else {
//             qualityLevels[i].enabled = false;
//           }
//         }
//         qualityLevels.trigger({
//           type: 'change',
//           selectedIndex: qualityLevels.selectedIndex_
//         });
//         prevCapping = sosCapping;
//         if (
//           document
//             ?.getElementById('video-tag')
//             ?.querySelectorAll(
//               '.vjs-control-bar > .vjs-http-source-selector'
//             )[0]
//         ) {
//           (
//             (
//               document.getElementById('video-tag') as HTMLElement
//             ).querySelectorAll(
//               '.vjs-control-bar > .vjs-http-source-selector'
//             ) as NodeListOf<HTMLElement>
//           )[0].style.visibility = 'hidden';
//         }
//       }
//     } else {
//       if (sosCapping !== prevCapping) {
//         qualityLevels.selectedIndex_ = -1;
//         qualityLevels.trigger({
//           type: 'change',
//           selectedIndex: qualityLevels.selectedIndex_
//         });
//         prevCapping = sosCapping;
//         if (
//           document
//             ?.getElementById('video-tag')
//             ?.querySelectorAll(
//               '.vjs-control-bar > .vjs-http-source-selector'
//             )[0]
//         ) {
//           (
//             (
//               document.getElementById('video-tag') as HTMLElement
//             ).querySelectorAll(
//               '.vjs-control-bar > .vjs-http-source-selector'
//             ) as NodeListOf<HTMLElement>
//           )[0].style.visibility = 'visible';
//         }
//       }
//     }
//   }
// }

export function SOShandler(
  videoPlayer: any,
  sosAssetType: any,
  sosAssetSubtype: any,
  sosCapping: any
) {
  if (videoPlayer && videoPlayer.qualityLevels) {
    const qualityLevels = videoPlayer.qualityLevels();
    const existingCapping =
      videoPlayer.qualityLevels().levels_[
        videoPlayer.qualityLevels().selectedIndex_
      ].height;
    if (
      isValidSOSAssetType(sosAssetType, sosAssetSubtype) &&
      qualityLevels &&
      qualityLevels.length > 0
    ) {
      if (isSOSCappingValid(sosCapping)) {
        if (sosCapping !== existingCapping) {
          for (let i = 0; i < qualityLevels.length; i++) {
            if (qualityLevels[i].height <= sosCapping) {
              qualityLevels[i].enabled = true;
              qualityLevels.selectedIndex_ = i;
            } else {
              qualityLevels[i].enabled = false;
            }
          }

          qualityLevels.trigger({
            type: 'change',
            selectedIndex: qualityLevels.selectedIndex_
          });
          // prevCapping = sosCapping;
          if (
            document
              ?.getElementById('video-tag')
              ?.querySelectorAll(
                '.vjs-control-bar > .vjs-http-source-selector'
              )[0]
          ) {
            (
              (
                document.getElementById('video-tag') as HTMLElement
              ).querySelectorAll(
                '.vjs-control-bar > .vjs-http-source-selector'
              ) as NodeListOf<HTMLElement>
            )[0].style.display = 'none';
          }
        }
      } else {
        if (sosCapping !== existingCapping) {
          qualityLevels.selectedIndex_ = -1;
          qualityLevels.trigger({
            type: 'change',
            selectedIndex: qualityLevels.selectedIndex_
          });
          // prevCapping = sosCapping;
          if (
            document
              ?.getElementById('video-tag')
              ?.querySelectorAll(
                '.vjs-control-bar > .vjs-http-source-selector'
              )[0]
          ) {
            (
              (
                document.getElementById('video-tag') as HTMLElement
              ).querySelectorAll(
                '.vjs-control-bar > .vjs-http-source-selector'
              ) as NodeListOf<HTMLElement>
            )[0].style.display = 'block';
          }
        }
      }
    }
  }
}

export function resetPlayerEventsHelperValuesOnDispose() {
  prevCapping = null;
}

// Business type check for getting premium content
function premiumBusinessType(type: string, billingType: string) {
  if (
    type === 'premium' ||
    type === 'premium_downloadable' ||
    billingType === 'club' ||
    billingType === 'premium'
  ) {
    return true;
  }
  return false;
}

function isCountryIndia() {
  const { playerConfig = {} } = playerRef;
  const { country = 'IN' } = playerConfig || {};
  return country && country.toLowerCase() === 'in';
}

export function toggleSubscribeTeaserBanner(show: boolean) {
  const teaserWrapper = document.querySelector<HTMLElement>('.teaser-wrapper');
  if (teaserWrapper) {
    if (show) {
      teaserWrapper.style.display = 'block';
    } else {
      teaserWrapper.style.display = 'none';
    }
  } else {
    show && invokeTeaserBanner();
  }
}

export function updateFreeMinutes(timeRemaining: any) {
  const { messages = {} } = (window as any).translationsAndConfigs;
  timeRemaining = new Date(timeRemaining * 1000)
    .toISOString()
    .substring(14, 19);
  const freeMinutesInfoContainer: HTMLElement | null = document.querySelector(
    '.freeMinutesInfoTimer'
  );
  if (freeMinutesInfoContainer) {
    freeMinutesInfoContainer.innerText = `${messages.FreeWatchEndsCounterHeading_Text} ${timeRemaining}`;
  }
}

export function setFreeMinutes(assetId: string, freeMinutes: number) {
  if (localStorage.getItem('freeAvailableSecs') === null) {
    const fmObj: any = {};
    fmObj[assetId] = freeMinutes;
    localStorage.setItem('freeAvailableSecs', JSON.stringify(fmObj));
  } else {
    try {
      const fmObj = JSON.parse(
        localStorage.getItem('freeAvailableSecs') as string
      );
      fmObj[assetId] = freeMinutes;
      localStorage.setItem('freeAvailableSecs', JSON.stringify(fmObj));
    } catch (error) {
      // failed to update localstroage, malformed object
    }
  }
}

export function getFreeMinutes(assetId: string) {
  if (localStorage.getItem('freeAvailableSecs') === null) {
    return null;
  } else {
    try {
      const fmObj = JSON.parse(
        localStorage.getItem('freeAvailableSecs') as string
      );
      if (fmObj[assetId] !== undefined) {
        return fmObj[assetId];
      } else {
        return null;
      }
    } catch (error) {
      // failed to get localstroage, malformed object
      return null;
    }
  }
}

function isSOSCappingValid(sosCapping: any) {
  if (sosCapping && typeof sosCapping == 'number' && sosCapping > 0) {
    return true;
  }
  return false;
}

function isValidSOSAssetType(sosAssetType: number, sosAssetSubtype: string) {
  const { playableData = {} }: any = playerRef || {};
  if (
    playableData.asset_type === sosAssetType &&
    playableData.asset_subtype === sosAssetSubtype
  ) {
    return true;
  }
  return false;
}

export function updateUpnextState(upNextStates: any) {
  isUpNextPending = upNextStates?.isUpNextPending;
  upNextApiState = upNextStates?.upNextApiState;
}
