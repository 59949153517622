// import videojs from 'video.js';

export function TapToUnmute() {
  const tapToUnmuteContainer = document.createElement('DIV');
  tapToUnmuteContainer.className = `tap_to_unmute`;
  tapToUnmuteContainer.innerHTML = 'Tap to Unmute';
  document
    .getElementsByClassName('video-js')[0]
    .appendChild(tapToUnmuteContainer);

  tapToUnmuteContainer.addEventListener('click', () => {
    const mutetoggleBtn = document.querySelector('.vjs-mute-control');

    if (mutetoggleBtn) {
      // Create a new click event
      let clickEvent = new MouseEvent('click', {
        bubbles: true
      });

      // Dispatch the click event on the element
      mutetoggleBtn.dispatchEvent(clickEvent);
    }
    if (document.querySelector('.tap_to_unmute')) {
      document
        .getElementsByClassName('video-js')[0]
        .removeChild(tapToUnmuteContainer);
    }
  });
}
