import EventEmitter from 'events';

import { VideoJsPlayer } from 'video.js';

import 'videojs-contrib-ads';
import { AdUi } from './ima/AdUi';
import { GoogleDAI } from './ima/GoogleDAI';
import { GoogleIMA } from './ima/GoogleIMA';
import { MediaAds } from './MediaAds';
// import { VmaxAds } from './VmaxAds';
import { AnalyticsManager } from '../analytics/AnalyticsManager';
import { ConvivaAnalyticsTracker } from '../analytics/ConvivaAnalyticsTracker';
import { AdClient, AdConfig } from '../configs/AdConfig';
import { Media } from '../configs/Media';
import { playerEventsConstants } from '../constants/playerConstants';
import { AdEvents } from '../events/AdEvents';

declare global {
  var google: any;
}
let convivaAnalyticsTracker: any;
export class AdsManager extends EventEmitter {
  videoPlayer: any;
  videoElement: Element;
  mediaSource?: Media;
  adConfig?: AdConfig;
  adProvider?: any;
  adUiContainer?: Element;
  adUi: AdUi;
  analyticsManager: AnalyticsManager;
  adLoadTimestamp: any;

  // constructor function
  constructor(player: VideoJsPlayer, element: Element) {
    super();
    this.videoPlayer = player;
    this.videoElement = element;
    this.adUiContainer = document.createElement('div');
    this.adUiContainer.id = 'ad-container';
    this.videoPlayer.el().appendChild(this.adUiContainer);
    this.adUi = new AdUi(player.el());
    convivaAnalyticsTracker = new ConvivaAnalyticsTracker();
    this.analyticsManager = AnalyticsManager.getInstance();
    this.adLoadTimestamp = new Date();
  }

  setConfig(mediaConfig: Media) {
    this.destroy();
    this.mediaSource = mediaConfig;
    this.adConfig = this.mediaSource.advertising;
    this.initializeAdProvider();
  }

  // initialization of ad provider
  private initializeAdProvider() {
    switch (this.adConfig?.client) {
      case AdClient.DAI:
        this.adProvider = new GoogleDAI(
          this.videoPlayer,
          this.videoElement,
          this.mediaSource,
          this
        );
        break;
      case AdClient.IMA:
        this.adProvider = new GoogleIMA(
          this.videoElement,
          this.adConfig.imaSettings!,
          this.videoPlayer,
          this
        );
        break;
      // case AdClient.VMAX:
      //   this.adProvider = new VmaxAds(
      //     this.videoElement,
      //     this.adConfig.vmaxSettings!,
      //     this.videoPlayer,
      //     this
      //   );
      //   break;
    }
    this.registerEvents();
  }

  private registerEvents() {
    if (this.adProvider) {
      this.adProvider.on(AdEvents.AD_LOADED, this.onAdLoaded.bind(this));
      this.adProvider.on(AdEvents.AD_STARTED, this.onAdStartedEvent.bind(this));
      this.adProvider.on(AdEvents.AD_ENDED, this.onAdCompletedEvent.bind(this));
      this.adProvider.on(AdEvents.COMPLETE, this.onAdCompletedEvent.bind(this));
      this.adProvider.on(
        AdEvents.AD_PROGRESS,
        this.onAdProgressEvent.bind(this)
      );
      this.adProvider.on(AdEvents.AD_ERROR, this.onAdError.bind(this));
      this.adProvider.on(
        AdEvents.AD_BREAK_STARTED,
        this.onAdBreakStart.bind(this)
      );
      this.adProvider.on(AdEvents.AD_BREAK_ENDED, this.onAdBreakEnd.bind(this));
      this.adProvider.on(AdEvents.SKIPPED, this.onAdSkipped.bind(this));
      this.adProvider.on(AdEvents.AD_PAUSE, this.onAdPaused.bind(this));
      this.adProvider.on(AdEvents.AD_CLICKED, this.onAdClicked.bind(this));
      this.adProvider.on(
        AdEvents.PRE_ROLL_COMPLETED,
        this.preRollCompleted.bind(this)
      );
      this.adProvider.on(
        AdEvents.STREAM_LOADED,
        this.googleDAIStreamLoaded.bind(this)
      );
      this.adProvider.on(
        AdEvents.STREAM_LOAD_ERROR,
        this.googleDAIStreamLoadError.bind(this)
      );
      this.adProvider.on(
        AdEvents.FIRST_QUARTILE,
        this.onAdFirstQuartile.bind(this)
      );
      this.adProvider.on(
        AdEvents.SECOND_QUARTILE,
        this.onAdSecondQuartile.bind(this)
      );
      this.adProvider.on(
        AdEvents.THIRD_QUARTILE,
        this.onAdThirdQuartile.bind(this)
      );
      this.adProvider.on(
        AdEvents.AD_IMPRESSION,
        this.onAdImpression.bind(this)
      );
      this.adProvider.on(AdEvents.REQUEST, this.onAdRequest.bind(this));
    }
  }

  // Google DAI stream loaaded listener function
  googleDAIStreamLoaded(streamURL: string) {
    console.log('VIDEOJS:', 'Google DAI stream loaded');
    this.emit(AdEvents.STREAM_LOADED, streamURL);
  }

  // Google DAI stream load error listener
  googleDAIStreamLoadError(error: any) {
    console.log('VIDEOJS:', 'Google DAI stream load error');
    this.emit(AdEvents.STREAM_LOAD_ERROR, error);
  }

  // Code will update the Ad related UI
  onAdPlayheadUpdated(
    currentTime: any,
    remainingTime: any,
    duration: any,
    adPosition: any,
    totalAds: any
  ) {
    this.adUi.updateAdUi(
      currentTime,
      remainingTime,
      duration,
      adPosition,
      totalAds
    );
  }

  private preRollCompleted(adEvent: any) {
    this.emit(AdEvents.PRE_ROLL_COMPLETED);
  }

  private onAdStartedEvent(adEvent: any) {
    if (this.adConfig?.client === 'VMAX') {
      this.analyticsManager.VmaxAdsInitilization(adEvent);
      this.analyticsManager.reportEvent(
        playerEventsConstants.adInitialized,
        adEvent
      );
    } else {
      this.adUi.showAdContainer();
      convivaAnalyticsTracker.adStartedEvent(adEvent);
      if (this.adProvider instanceof GoogleDAI) {
        this.adLoadTimestamp = new Date();
        this.analyticsManager.AdsInitilization(adEvent);
        this.analyticsManager.reportEvent(
          playerEventsConstants.adInitialized,
          adEvent
        );
      }
      let adStartTime = 'N/A';
      if (this.adLoadTimestamp) {
        adStartTime = (
          window.Math.abs(
            (new Date() as any) - (new Date(this.adLoadTimestamp) as any)
          ) / 1000
        ).toString();
        this.adLoadTimestamp = null;
        adEvent.adStartTime = adStartTime;
      } else {
        adEvent.adStartTime = 'N/A';
      }
      this.analyticsManager.reportEvent(
        playerEventsConstants.adStarted,
        adEvent
      );
      this.emit(AdEvents.AD_STARTED);
    }
  }

  private onAdLoaded(adEvent: any) {
    // Ads, Need to set videoplayer for popups,
    try {
      window.convivaSDK.setPlayer(this.videoPlayer);
      console.log('onAdLoaded, VideoPlayer Object integrated to ConvivaSDK');
    } catch (error) {
      console.error(
        'ADS, Error while adding video Player to conviva SDK',
        error
      );
    }
    convivaAnalyticsTracker.adLoaded();
    if (this.adProvider instanceof GoogleIMA) {
      this.adLoadTimestamp = new Date();
      this.analyticsManager.AdsInitilization(adEvent);
      this.analyticsManager.reportEvent(
        playerEventsConstants.adInitialized,
        adEvent
      );
    }
  }

  private onAdPaused(adEvent: any) {
    if (this.adProvider instanceof GoogleDAI) {
      convivaAnalyticsTracker.onAdPaused();
      this.analyticsManager.reportEvent(
        playerEventsConstants.adPaused,
        adEvent
      );
    }
  }
  private onAdCompletedEvent(adEvent: any) {
    if (this.adLoadTimestamp) {
      this.adLoadTimestamp = null;
    }
    this.adUi.hideAdContainer();
    convivaAnalyticsTracker.adCompletedEvent();
    this.analyticsManager.reportEvent(
      playerEventsConstants.adCompleted,
      adEvent
    );
    this.emit(AdEvents.AD_COMPLETE);
  }
  private onAdProgressEvent(adEvent: any) {
    if (this.adProvider instanceof GoogleDAI) {
      convivaAnalyticsTracker.adProgressEvent();
    }
  }
  private onAdError(adError: any) {
    if (this.adLoadTimestamp) {
      this.adLoadTimestamp = null;
    }
    this.emit(AdEvents.AD_ERROR, adError);
    this.adUi.hideAdContainer();
    this.analyticsManager.reportEvent(playerEventsConstants.adErrors, adError);
    convivaAnalyticsTracker.adError(adError);
  }
  private onAdBreakStart(adEvent: any) {
    this.adUi.showAdContainer();
    if (this.adProvider instanceof GoogleDAI) {
      convivaAnalyticsTracker.adBreakStart();
    }
  }
  private onAdBreakEnd(adEvent: any) {
    this.adUi.hideAdContainer();
    if (this.adProvider instanceof GoogleDAI) {
      convivaAnalyticsTracker.adBreakEnd();
    }
  }
  private onAdSkipped(adEvent: any) {
    this.adUi.hideAdContainer();
    if (this.adProvider instanceof GoogleDAI) {
      convivaAnalyticsTracker.adSkipped();
    }
    this.analyticsManager.reportEvent(playerEventsConstants.adSkipped, adEvent);
  }

  private onAdClicked(adEvent: any) {
    this.analyticsManager.reportEvent(playerEventsConstants.adClicked, adEvent);
  }

  private onAdImpression(adEvent: any) {
    this.analyticsManager.reportEvent(
      playerEventsConstants.adImpression,
      adEvent
    );
  }
  private onAdFirstQuartile(adEvent: any) {
    this.analyticsManager.reportEvent(
      playerEventsConstants.adFirstQuartile,
      adEvent
    );
  }
  private onAdSecondQuartile(adEvent: any) {
    this.analyticsManager.reportEvent(
      playerEventsConstants.adSecondQuartile,
      adEvent
    );
  }
  private onAdThirdQuartile(adEvent: any) {
    this.analyticsManager.reportEvent(
      playerEventsConstants.adThirdQuartile,
      adEvent
    );
  }
  private onAdRequest(adEvent: any) {
    this.analyticsManager.reportEvent(playerEventsConstants.adRequest, adEvent);
  }

  // code returns true if ads playing
  isAdBreak(): boolean {
    if (this.adProvider) return this.adProvider.isAdBreak;
    return false;
  }

  // Pauses the current ad that is playing
  pauseAd() {
    if (this.adProvider) {
      this.adProvider.pause();
    }
  }

  // Resumes the current ad that is loaded and paused
  resumeAd() {
    if (this.adProvider) {
      this.adProvider.resume();
    }
  }

  // destroys ad provider
  destroy() {
    if (this.adProvider) {
      this.adUi.hideAdContainer();
      this.deregisterEvents();
      this.adProvider.destroy();
      this.adProvider = null;
    }
  }

  private deregisterEvents() {
    this.adProvider.removeListener(
      AdEvents.AD_LOADED,
      this.onAdLoaded.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_STARTED,
      this.onAdStartedEvent.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_ENDED,
      this.onAdCompletedEvent.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.COMPLETE,
      this.onAdCompletedEvent.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_PROGRESS,
      this.onAdProgressEvent.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_ERROR,
      this.onAdError.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_BREAK_STARTED,
      this.onAdBreakStart.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_BREAK_ENDED,
      this.onAdBreakEnd.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.SKIPPED,
      this.onAdSkipped.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_PAUSE,
      this.onAdPaused.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.AD_CLICKED,
      this.onAdClicked.bind(this)
    );
    this.adProvider.removeListener(
      AdEvents.PRE_ROLL_COMPLETED,
      this.preRollCompleted.bind(this)
    );
  }
}
