import { ZeePlayerConfig } from '../configs/ZeePlayerConfig';
import {
  createElement,
  toggleWaterMark,
  toggleFreeMinutes
} from '../utils/Utils';

let infoTimer: any = null;

export function showContentDescriptor(playerConfig: ZeePlayerConfig) {
  if (infoTimer === null) {
    removeScorecardWidget();
    hideTrailerInfo();
    addContentDescriptor(playerConfig);
    toggleFreeMinutes(false);
    toggleWaterMark(false);
    infoTimer = setTimeout(() => {
      removeContentDescriptor(playerConfig);
      clearInfoTimer();
    }, 5000);
  }
}

export function removeScorecardWidget() {
  const container: any = document.querySelector('.scoreMainContainer');
  if (container) {
    container.style.visibility = 'hidden';
  }
}

export function enableScorecardWidget() {
  const container: any = document.querySelector('.scoreMainContainer');
  if (container) {
    container.style.visibility = 'visible';
  }
}

function addContentDescriptor(playerConfig: ZeePlayerConfig) {
  const playerPlaceholder = document.getElementById(playerConfig.videoTagId);
  let infoContainer = () => document.querySelector('.info-container');
  if (playerPlaceholder && infoContainer())
    playerPlaceholder.removeChild(infoContainer() as any);

  const pathname = window.location && window.location.pathname;
  const isNewsContent =
    (pathname &&
      pathname.split('/') &&
      pathname.split('/').length &&
      pathname.split('/')[1] === 'news') ||
    playerConfig.contentType === 'livetv';
  //hide for global and new content
  if (isNewsContent) {
    return null;
  }

  const {
    assetDetails: {
      age_rating: ageRating = 'U',
      extended_wh: { content_descriptors: contentDescriptors = [] } = {}
    } = {}
  } = playerConfig.singlePlayDetails || {};

  let contentDescriptorString = '';
  if (contentDescriptors && contentDescriptors.length > 0) {
    contentDescriptorString = contentDescriptors.join(', ');
  }

  createElement({
    element: 'div',
    text: '',
    className: 'info-container',
    attributes: [
      {
        attribute: 'class',
        value: 'info-container'
      }
    ],
    targetDiv: playerPlaceholder as HTMLElement
  });

  createElement({
    element: 'div',
    className: 'info-container--rating',
    text: `Rated ${ageRating}`,
    attributes: [
      {
        attribute: 'class',
        value: 'rating'
      }
    ],
    targetDiv: infoContainer() as HTMLElement
  });

  if (
    contentDescriptorString &&
    contentDescriptorString.length > 0 &&
    infoContainer()
  ) {
    createElement({
      element: 'p',
      className: 'info-container--descriptor',
      text: contentDescriptorString,
      attributes: [
        {
          attribute: 'class',
          value: 'description'
        }
      ],
      targetDiv: infoContainer() as HTMLElement
    });
  }
}

function removeContentDescriptor(playerConfig: ZeePlayerConfig): void {
  const playerPlaceholder = document.getElementById(playerConfig.videoTagId);
  const infoContainer = document.querySelector('.info-container');
  if (playerPlaceholder && infoContainer) {
    infoContainer.className = 'info-container info-container-inverse';
    const timerId = setTimeout(() => {
      if (infoContainer) {
        playerPlaceholder.removeChild(infoContainer);
      }
      clearTimeout(timerId);
    }, 400); // Timer to show fadeout effect
  }
  setTimeout(() => {
    showTrailerInfo();
  }, 500);
  toggleFreeMinutes(true);
  toggleWaterMark(true);
  setTimeout(() => {
    enableScorecardWidget();
  }, 1400);
}

function clearInfoTimer() {
  if (infoTimer) {
    clearTimeout(infoTimer);
    infoTimer = null;
  }
}

function hideTrailerInfo() {
  const container: any = document.querySelector('.trailerInfoContainer');
  if (container) {
    container.style.visibility = 'hidden';
  }
}

function showTrailerInfo() {
  const container: any = document.querySelector('.trailerInfoContainer');
  if (container) {
    container.style.visibility = 'visible';
  }
}
