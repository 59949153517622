export function initialCommerceIcon() {
  const shopIconContainer = document.createElement('div');
  shopIconContainer.setAttribute('class', 'initialShopIconContainer');
  shopIconContainer.setAttribute('id', 'initialShopIconContainer');
  const logo = document.createElement('span');
  logo.setAttribute('class', 'shopIcon');
  shopIconContainer.appendChild(logo);
  const span = document.createElement('span');
  const {
    messages: { ContextualCommerce_ShopNudge = 'Buy what you see' } = {}
  } = window.translationsAndConfigs || {};
  span.innerText = ContextualCommerce_ShopNudge;
  span.setAttribute('class', 'calloutContainer');
  shopIconContainer.appendChild(span);
  const videoPlayer = document.getElementById('video-tag');
  videoPlayer?.appendChild(shopIconContainer);
}

export function adjustShopIconContainerOnChromecast(add = false) {
  const shopIconContainer = document.getElementById('initialShopIconContainer');
  const shopIconContainerClassList = shopIconContainer?.classList;
  if (shopIconContainerClassList && shopIconContainerClassList.length) {
    add
      ? shopIconContainerClassList?.add('adjustShopIconContainerOnChromecast')
      : shopIconContainerClassList?.remove(
          'adjustShopIconContainerOnChromecast'
        );
  }
}
